import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRINTABLE: 'Для печати',
        GLUEABLE: 'Приклеивается',
        MOUNTABLE: 'Монтируется',
      },
    }
  }
}
