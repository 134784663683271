import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Label template |||| Шаблоны этикеток',
      
      loadAction: {
        action: 'Загрузка',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      fields: {
        companyUniqName: 'Компания в системе',
        creatorUsername: 'Имя создателя',
        defaultTemplate: 'Этикетка по умолчанию',
        guid: 'GUID',
        name: 'Имя',
        path: 'Путь',
        status: 'Статус',
        statusChangeDescription: 'Изменение статуса',
        type: 'Тип',
        updateDate: 'Дата обновления',
        createDate: 'Дата создания',
      }
    }
  }
}