import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, SimpleForm, Toolbar, SaveButton, showNotification, FileInput, FileField } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import { paramToDictionary } from '../../../../utils';
import request from '../../../../network/request';
import labelTemplate from '../labelTemplate';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15
  }
};

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar
    style={styles.toolbar}
    {...props}
  >
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
    />
    {props.saving &&
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    }
  </Toolbar>
))

class AppModule extends Component {
  defaultParams = {
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      executing: false,
      params: {
        ...this.defaultParams
      }
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);
    if (data.labelId) {
      data.labelId = parseInt(data.labelId)
    }
    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams
      }
    })
  }

  execute = async (formData) => {

    this.setState({
      executing: true
    });
    let data = {
      ...formData
    }
    let response = null;
    try {
      var fetchBody  = new FormData();

      if (data.file && data.file.rawFile) {
        fetchBody.append('file', data.file.rawFile);
      }
      delete data.file
      for(var name in data) {
        fetchBody.append(name, data[name]);
      }

      response = await request({
        apiService: resourceConfig.apiService,
        path: resourceConfig.apiPath,
        body: fetchBody,
        method: 'POST',
      })
    } catch (e) {
      console.error(e);
    }
    if (response === null || !response.ok) {
      this.props.showNotification(`resources.${resourceConfig.name}.errorMessage`, 'error');
    } else {
      this.props.showNotification(`resources.${resourceConfig.name}.successMessage`, 'success');
      this.props.history.goBack();
    }

    this.setState({
      executing: false
    });
  }

  handleCloseClick = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      translate
    } = this.props;

    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.handleCloseClick}
      >
        <SimpleForm
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar />}
          saving={this.state.executing}
        >
          <Typography
            variant={'title'}
          >
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <labelTemplate.Input source={'labelId'} />

          <FileInput source="file">
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Drawer>
    )
  }
}

export default compose(
  withRouter,
  connect(null, { showNotification }),
  translate,
  withStyles(styles)
)(AppModule);
