import React from 'react';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';


const chipListStyles = {
  root: {
    marginLeft: -5,
    marginRight: -5,
  },
  chip: {
    marginLeft: 5,
    marginRight: 5,
  }
};
const ChipList = ({ classes, items = [] }) => (
  <span className={classes.root}>
    {items &&
      items.map(x => (
        <Chip
            key={x}
            label={x}
            className={classes.chip}
        />
      ))}
  </span>
);

export default compose(withStyles(chipListStyles))(ChipList);