import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Отправить заказ',
      title: 'Отправить заказ',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Заказ успешно отправлен',
    }
  }
}