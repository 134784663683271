export default {
  configUrl: process.env.REACT_APP_CONFIG_URL || '/config.json',
  apiDateFormat: 'YYYY-MM-DD',
  apiDateTimeFormat: process.env.REACT_APP_API_DATE_FORMAT || 'YYYY-MM-DD HH:mm:ss',
  services: {
    auth: {
      baseUrl: process.env.REACT_APP_API_AUTH_BASE_URL || '',
    },
    account: {
      baseUrl: process.env.REACT_APP_API_ACCOUNT_BASE_URL || '',
    },
    shoes: {
      baseUrl: process.env.REACT_APP_API_SHOES_BASE_URL || '',
    },
    marking: {
      baseUrl: process.env.REACT_APP_API_MARKING_BASE_URL || process.env.REACT_APP_API_SHOES_BASE_URL || '',
    },
    keycloak: {
      baseUrl: process.env.REACT_APP_API_KEYCLOAK_BASE_URL || '',
      realm: process.env.REACT_APP_API_KEYCLOAK_REALM || 'marking', // #TODO remove
      clientId: process.env.REACT_APP_API_KEYCLOAK_CLIENT_ID || '',
      clientSecret: process.env.REACT_APP_API_KEYCLOAK_CLIENT_SECRET || '',
    }
  }
}