import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ошибка фоновой задачи |||| Ошибки фоновых задач',
      fields: {
        createdDate: 'Дата',
        taskManagerModelId: 'Задачи',
        shortMessage: 'Сообщение',
        message: 'Полное сообщение',
        functionType: 'Тип функции',
        httpClientMess: 'HTTP сообщение',
      }
    }
  }
}