import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции заказов |||| Позиции заказов',
      orderM: 'Подзаписи',
      ordersPool: 'Пулы заказов',
      fields: {
        id: '#',
        availableCodes: 'Доступно кодов',
        blockId: 'ID блока',
        bufferStatus: 'Стату буфера',
        gtin: 'GTIN',
        leftInBuffer: 'Осталось в буфере',
        numpos: 'Номер позиции',
        orderHId: 'Id заказа',
        poolsExhausted: 'Пулы исчерпаны',
        quantity: 'Количество',
        rejectionReason: 'Причина отказа',
        serialNumberType: 'Тип серийного номера',
        unavailableCodes: 'Недоступно кодов',
        codeQuantity: 'Количество кодов',
        codeType: 'Тип кода',
        emissionType: 'Тип эмиссии',
        markingType: 'Тип маркировки',
        numdoc: 'Номер документа',
        orderLineId: 'Id Линии заказа',
        serialNumber: 'Серийные номера',
        status: 'Статус',
        tnVedCode: 'ТН ВЭД код',
        templateKMId: 'Id шаблона',
        fullKM: 'Полный код',
        storageUnit: 'Блок хранения',
        ean: 'EAN',
        isDefault: 'По-умолчанию',

        numGoods: 'Номер товара',
        invoice: 'Номер инвойса',
        dateInvoice: 'Дата инвойса',

        cisType: 'Тип кода маркировки',
        exporterTaxpayerId: 'ИНН/УНБ (или аналог) экспортера',
        expDate72: 'Дата окончания срока годности продукции (срок хранения менее 72 часов)',
        expDate: 'Дата инвойса Дата окончания срока годности продукции (срок хранения более 72 часов)',

        manufacturingItem: 'Производственный код товара',
        itemCode: 'Код товара',
      },
    }
  }
}