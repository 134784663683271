import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Error |||| Errors',
      createOrder: {
      }
    }
  }
}