import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Архив сообщений |||| Архив сообщений',
      fields: {
        id: 'Id',
        functionType: 'Тип функции',
        message: 'Сообщение',
        sendDate: 'Дата отправки',
        creatorUsername: 'Имя создателя',
        companyUniqName: 'Компания в системе',
        ids: 'Id',
        documentLinkType: 'Тип документа',
        documentLinkId: 'Id документа',
      },
    }
  }
}