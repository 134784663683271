import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CONSUMER': 'CONSUMER',
        'GROUP': 'GROUP',
        'TRANSPORTATION': 'TRANSPORTATION',
      },
    }
  }
}
