import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        KM_SPOILED: 'KM_SPOILED',
        KM_LOST: 'KM_LOST',
        KM_DESTROYED: 'KM_DESTROYED',
      },
    }
  }
}