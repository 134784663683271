import React, { PureComponent } from 'react';
import ReactXMLViewer from 'react-xml-viewer'

class XMLViewer extends PureComponent {
  render() {
    return (
      <ReactXMLViewer xml={this.props.xml} />
    );
  }
}

export default XMLViewer;