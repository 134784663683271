import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        OWN_PRODUCTION: 'OWN_PRODUCTION',
        CONTRACT_PRODUCTION: 'CONTRACT_PRODUCTION',
      },
    }
  }
}