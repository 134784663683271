import React, { Component } from 'react';
import { DateInput as DateInputRA } from './DateInputRA';

class DateInput extends Component {
  render() {
    return (
      <DateInputRA
        options={{ 
          format: 'dd/MM/yyyy',
          ...this.props.options
        }}
        {...this.props}
      />
    );
  }
}

export default DateInput;