import React, { Fragment } from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    BulkDeleteButton,
    DateField,
    CreateButton,
    ExportButton,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import LinkToList from './LinkToList';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import exporter from '../../../../utils/exporter';
import eanInfoUpload from '../eanInfoUpload';

const ResourceIcon = BookmarkIcon;

const listStyles = {
};

const ResourceBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
        <TextInput source={'ids'} />
        <TextInput source={'ean'} />
        <TextInput source={'gtin'} />
    </Filter>
);


const ResourceListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    translate
}) => (
    <Toolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <eanInfoUpload.OpenButton />
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        actions={<ResourceListActions />}
        filters={<ResourceListFilter />}
        bulkActionButtons={<ResourceBulkActionButtons />}
        exporter={exporter}
    >
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'} />

            <TextField source={'ean'} />
            <TextField source={'gtin'} />
            <DateField source={'dateFrom'} />
            <DateField source={'dateTo'} />
            
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.id
        }&quot;
    </span>
));


const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source={'ean'} />
            <TextInput source={'gtin'} />
            <DateInput source={'dateFrom'} label={`resources.${resourceConfig.name}.fields.dateFrom`} />
            <DateInput source={'dateTo'} label={`resources.${resourceConfig.name}.fields.dateTo`} />
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm redirect={false}>
            <TextInput source={'ean'} />
            <TextInput source={'gtin'} />
            <DateInput source={'dateFrom'} label={`resources.${resourceConfig.name}.fields.dateFrom`} />
            <DateInput source={'dateTo'} label={`resources.${resourceConfig.name}.fields.dateTo`} />
        </SimpleForm>
    </Edit>
);

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <TextField source={'id'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={'id'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
);



export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,
    
    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name, 
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
            dateFrom: {
                type: 'dateTime',
            },
            dateTo: {
                type: 'dateTime',
            },
        },
        filters: {
            dateFrom: {
                type: 'dateTime',
            },
            dateTo: {
                type: 'dateTime',
            },
        }
    }
}
