import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'NO_TREE': 'Не возвращать вложенные КИ',
        'ONE_LEVEL': 'Возвращать только первый уровень вложенности',
        'ALL_TREE': 'Возвращать все дерево',
      },
    }
  }
}
