import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Из док-а',
      title: 'Загрузка из документа',
      executeAction: 'Загрузить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      fields: {
        contractDate: 'Дата контракта',
      },
    }
  }
}