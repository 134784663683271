import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRINTABLE: 'Printable',
        GLUEABLE: 'Glueable',
        MOUNTABLE: 'Mountable',
      },
    }
  }
}