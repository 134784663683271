import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Order M |||| Order M records',
      fields: {
        id: '#',
        orderPId: 'Позиция заказа',
        nummk: 'NUMMK',
        serialNumbers: 'Серийные номера',
        templateId: 'Id шаблона',
      },
    }
  }
}