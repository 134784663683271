import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'EAN Info |||| EAN Infos',
      fields: {
        dateFrom: 'Date from',
        dateTo: 'Date to',
      }
    }
  }
}