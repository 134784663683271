import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        AGGREGATION: 'Aggregation',
        DISAGGREGATION: 'Disaggregation',
        REMOVING: 'Removing',
        ADDING: 'Adding',
        SETS_AGGREGATION: 'Sets Aggregation',
      },
    }
  }
}