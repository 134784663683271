import SecurityIcon from '@material-ui/icons/Security';
import locales from './locales';

import user from './resources/user';
import authority from './resources/authority';
import permission from './resources/permission';

const resources = [
  user,
  authority,
  permission,
];

export default {
  name: 'auth',
  locales,
  resources,
  icon: SecurityIcon,
  documentation: {
    swaggerResourcesPath: '/swagger-resources'
  }
};
