import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN_PROGRESS: 'Проверяется',
        CHECKED_OK: 'Оформлен (не используется для документа "Отгрузка товара")',
        CHECKED_NOT_OK: 'Ошибка при проверки',
        PROCESSING_ERROR: 'Ошибка при обработки',
        UNDEFINED: 'Не определен',
        CANCELLED: 'Отмена отгрузки (только для документа "Отгрузка товара")',
        ACCEPTED: 'Отгрузка принята (только для документа "Отгрузка товара")',
        WAIT_ACCEPTANCE: 'Отгрузка ожидает приемки (только для документа "Отгрузка товара")',
        WAIT_PARTICIPANT_REGISTRATION:
          'Отгрузка незарегистрированному участнику (только для документа "Отгрузка товара")',
        PENDING: 'Буфер КМ в ожидании',
        ACTIVE: 'Буфер КМ активен',
        EXHAUSTED: 'Буфер КМ не содержит кодов',
        REJECTED: 'Буфер не доступен',
        CLOSED: 'Буфер закрыт',
        ERROR: 'Ошибка отправки',
        NK_REQUEST_ERROR: 'Нац. каталог ошибки',
      },
    },
  },
};
