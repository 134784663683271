import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Warehouse |||| Warehouses',
      arrayInputLabel: 'Warehouses',
      setAsDefault: {
        action: 'Set as default',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
    }
  }
}