import React from 'react';
import { translate } from 'react-admin';
import getValue from '../../utils/getValue';

export default translate((props) => {
  const {
    source,
    record,
    emptyText,
    translate,
    translatePrefix,
  } = props;
  
  let value = getValue(record, source) || '';
  if (value && translatePrefix) {
    value = `${translatePrefix}${value}`
  }
  
  return (
    <span>{translate(value, emptyText)}</span>
  );
})