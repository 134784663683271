export default {
  services: {
    marking: {
      name: 'Marking service'
    }
  },
  documentsSendError: {
    sending: 'Document # %{id} sent',
    success: 'Document # %{id} received',
  }
}