import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Проверка KM',
      title: 'Проверка KM',
      executeAction: 'Проверить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
    }
  }
}