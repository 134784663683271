import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        EMITTED: 'Emitted',
        APPLIED: 'Applied',
        INTRODUCED: 'Introduced',
        WRITTEN_OFF: 'Written off',
        WITHDRAWN: 'Withdrawn',
        UNDEFINED: 'Undefined',
        RETIRED: 'Retired',
        DISAGGREGATION: 'DISAGGREGATION',
        WAIT_FOR_CONTINUATION: 'WAIT_FOR_CONTINUATION',
      },
    }
  }
}