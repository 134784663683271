import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        AGGREGATION: 'Агрегация',
        DISAGGREGATION: 'Расформирование',
        REMOVING: 'Изъятие',
        ADDING: 'Добавление',
        SETS_AGGREGATION: 'Формирование наборов JSON (MANUAL)',
      },
    }
  }
}
