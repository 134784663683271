export default {
  enums: [
    { id: 'NEW' },
    { id: 'SENDING_DOC' },
    { id: 'SENT_DOCUMENT' },
    { id: 'LOAD_DOCUMENT' },
    { id: 'ERROR' },
    { id: 'CHECK_TICKET' },
    { id: 'BAD_TICKET' },
    { id: 'WAITING_STATUS' },
  ]
};