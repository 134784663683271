import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Импортировать документ',
      title: 'Импортировать документ',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      fields: {
      },
    }
  }
}