import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Повторная печать',
      title: 'Повторная печать',
      executeAction: 'Отправить',
      resetAction: 'Сбросить',
      errorMessage: 'Ошибка',
      nothingToCreate: 'Не создано ни одного журнала',
      fields: {
        file: 'Файл',
        label: 'Шаблон',
      },
    }
  }
}