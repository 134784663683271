import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Jasper',
      title: 'Jasper',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
    }
  }
}