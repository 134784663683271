import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import { translate, Title, SimpleForm, Toolbar, SaveButton, showNotification, TextInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import request from '../../network/request';

const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
};


const FormToolbar = translate(({ translate, ...props }) => (
    <Toolbar
      style={styles.toolbar}
      {...props}
    >
      <SaveButton
        redirect={false}
        submitOnEnter={true}
      />
      {props.saving &&
        <div style={styles.loader}>
          <CircularProgress />
        </div>
      }
    </Toolbar>
  ))

class ChangePassword extends Component {

    state={
        requestKey: Math.random(),
        saving: false,
    }

    execute = async (formData) => {
        this.setState({
          saving: true
        });
        let data = {
          ...formData
        }
        let response = null;
        try {
          response = await request({
            apiService: 'auth',
            path: '/users/current/password',
            body: JSON.stringify(data),
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            }
          })
        } catch (e) {
          console.error(e);
        }
        console.log(response);
        if (response !== null && response.status === 400) {
            this.props.showNotification(response.data.message, 'error');
        } else if (response === null || !response.ok) {
          this.props.showNotification('changePassowrdPage.errorMessage', 'error');
        } else {
          this.props.showNotification('changePassowrdPage.successMessage', 'success');
        }
    
        this.setState({
            requestKey: Math.random(),
            saving: false
        });
      }

    render() {
        const {
            translate,
        } = this.props;


        return (
            <Card>
                <Title title={translate('changePassowrdPage.title')} />
                <SimpleForm
                    save={this.execute}
                    toolbar={<FormToolbar />}
                    saving={this.state.saving}
                    key={this.state.requestKey}
                >
                    <Typography
                        variant={'h5'}
                    >
                        {translate('changePassowrdPage.title')}
                    </Typography>

                    <TextInput source="password" type="password" label="changePassowrdPage.fields.password"/>
                    <TextInput source="newPassword" type="password" label="changePassowrdPage.fields.newPassword" />
                </SimpleForm>
            </Card>
        );
    }
}

export default compose(
    connect(null, { showNotification }),
    translate,
    withStyles(styles)
)(ChangePassword);
