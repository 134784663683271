import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Upload',
      title: 'Goods upload',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'All %{count} entries updated',
      updatedItemsCount: 'Updated entries count',
      notFoundGtins: 'Not found GTINs',
      fields: {
      },
    }
  }
}