import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Load custom',
      title: 'Load custom',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        'cisPackageTypeUNIT': 'Cis package type',
        'documentId': 'Document id',
        'packs': 'Packs',
        'statusExt': 'Status ext',
        'tree': 'Tree',
      }
    }
  }
}