// import green from '@material-ui/core/colors/green';
// import red from '@material-ui/core/colors/red';
// import lime from '@material-ui/core/colors/lime';
// import deepOrange from '@material-ui/core/colors/deepOrange';
// import cyan from '@material-ui/core/colors/cyan';

export default {
  enums: [
    { id: 'UPLOADING_DOCUMENT'},
    { id: 'PROCESSING_DOCUMENT'},
    { id: 'CORE_PROCESSING_DOCUMENT'},
    { id: 'CORE_PROCESSED_DOCUMENT'},
    { id: 'PROCESSED_DOCUMENT'},
    { id: 'FAILED'},
    { id: 'FAILED_RESULT_READY' }
  ]
};