export default (data) => {
  const result = {

  };

  if (typeof data === 'string') {
    data.split('\n').forEach(x => {
      const parts = x.split('=');
      result[parts[0]] = parts[1];
    });
  }

  return result;
}