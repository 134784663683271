import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRODUCT: 'Product',
        PRODUCT_PACKAGE: 'Product package',
        PRODUCT_LABEL: 'Product label',
      },
    }
  }
}