import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

export default (records, fetchRelatedRecords, proxy, name) => {
    jsonExport(records, {
      rowDelimiter: ';',
      forceTextDelimiter: true,
    }, (err, csv) => {
        downloadCSV(`\uFEFF ${csv}`, name); // download as 'posts.csv` file
    });
}