export default {
  enums: [
    { id: 'ACTIVE' },
    { id: 'CLOSED' },
    { id: 'PENDING' },
    { id: 'REJECTED' },
    { id: 'EXHAUSTED' },
    { id: 'CREATE' },
    { id: 'DECLINE' },
  ]
};