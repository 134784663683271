import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        OWN_PRODUCTION: 'Своя продукция',
        CONTRACT_PRODUCTION: 'По контракту',
      },
    }
  }
}
