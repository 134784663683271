import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Пул заказов |||| Пулы заказов',
      fields: {
        id: '#',
        orderPId: 'Позиция заказа',
        lastRegistrarErrorTimestamp: 'Отметка времени последней ошибки регистратора',
        leftInRgistrar: 'Осталось в регистраторе',
        poolInfos: 'Пул инфо',
        quantity: 'Количество',
        registrarErrorCount: 'Количество ошибок регистртора',
        registrarId: 'Id Регистратора',
        registrarReady: 'Регистратор готов',
        rejectionReason: 'Причина отказа',
        REQUEST_ERROR: 'Ошибка запроса',
      },
    }
  }
}