import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DOC: 'Документ',
        GOODS: 'Товар',
        INPUT: 'Ввод в оборот',
        ORDER: 'Заказ',
        OUT: 'Вывод из оборота',
        REMARK: 'Перемаркировка',
        TREEMRK: 'Код маркировки',
        REPORT: 'Отчет об использовании',
        ORDER_P: 'Позиция заказа',
        DESC_REST: 'Остатки',
        AGGR: 'Агрегация',
        RETURN: 'Возврат',
        STRDOC: 'STRDOC',
        ATK: 'ATK',
        NK_REQUEST: 'НК Запрос',
        IMPORT_THIRD_COUNTRY: 'Ввоз из третьих стран',
      },
    },
  },
};
