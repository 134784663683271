import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';

import resourceConfig from './config';

const stopPropagation = e => e.stopPropagation();

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};

const LinkToRelatedCustomers = ({ classes, translate, filter, ...props }) => (
    <Button
        size="small"
        color="primary"
        {...props}
        component={Link}
        onClick={stopPropagation}
        to={{
            pathname: `/${resourceConfig.name}`,
            search: stringify({
                filter: JSON.stringify(filter || {}),
            }),
        }}
        className={classes.link}
    >
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 2 })}
    </Button>
);

const enhance = compose(
    withStyles(styles),
    translate
);
export default enhance(LinkToRelatedCustomers);