import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import ChangePassword from './components/ChangePassword';
import SearchKMPage from './components/SearchKMPage';


export default [
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/change-password" component={ChangePassword} />,
    <Route exact path="/search-km" component={SearchKMPage} />,
];
