import deepmerge from 'deepmerge';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import en from './langs/en';
import ru from './langs/ru';

export const defaultLang = 'en';
let langs = {
  en,
  ru,
}

export const registerLocales = (locales) => {
  langs = deepmerge(langs, locales);
}

export const getLang = (lang) => {
  if (lang in langs) {
    return langs[lang];
  }
  return langs[defaultLang];
}

export const getLangFromStorage = () => {
  const lang = localStorage.getItem('lang');
  if (lang) {
    return lang;
  }
  return defaultLang
}

export const setLangToStorage = (lang) => {
  localStorage.setItem('lang', lang);
}

export const getI18nProvider = () => polyglotI18nProvider((lang) => getLang(lang), getLangFromStorage());