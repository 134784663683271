import React from 'react';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToParam } from '../../../../utils';
import resourceConfig from './config';

const OpenButton = ({ params, translate, ...props }) => {
  const urlPatams = {
    ...params,
  };

  return (
    <Button
      size={'small'}
      color={'primary'}
      {...props}
      component={TransitionLink}
      transitionTo={{
        params: {
          [resourceConfig.urlParamName]: dictionaryToParam(urlPatams),
        },
      }}>
      {translate(`resources.${resourceConfig.name}.openAction`)}
    </Button>
  );
};

export default translate(OpenButton);
