import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Aggregate',
      title: 'MRK aggregation',
      executeAction: 'Aggregate',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        sscc: 'SSCC',
        mrkIds: 'Items',
        participantId: 'Participant'
      }
    }
  }
}