import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UNIT: 'UNIT',
        LEVEL1: 'LEVEL1',
        LEVEL2: 'LEVEL2',
        LEVEL3: 'LEVEL3',
        LEVEL4: 'LEVEL4',
        LEVEL5: 'LEVEL5',
        BUNDLE: 'BUNDLE ',
        SET: 'SET ',
      },
    },
  },
};
