import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import treeMrkAggregation from '../treeMrkAggregation'

class BulkButton extends Component {
    render() {
        
        return (
            <treeMrkAggregation.OpenButton
                disabled={this.props.selectedIds.length === 0}
                params={{
                    ids: this.props.selectedIds
                }}
            />
        );
    }
}

BulkButton.propTypes = {
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default connect(
    undefined,
    {  }
)(BulkButton);