import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DOC: 'DOC',
        GOODS: 'GOODS',
        INPUT: 'INPUT',
        ORDER: 'ORDER',
        STRDOC: 'STRDOC',
        OUT: 'OUT',
        REMARK: 'REMARK',
        TREEMRK: 'TREEMRK',
        REPORT: 'REPORT',
        ORDER_P: 'ORDER_P',
        DESC_REST: 'DESC_REST',
        AGGR: 'AGGR',
        RETURN: 'RETURN',
        ATK: 'ATK',
        NK_REQUEST: 'NK_REQUEST',
        IMPORT_THIRD_COUNTRY: 'IMPORT_THIRD_COUNTRY',
      },
    },
  },
};
