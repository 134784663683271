import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Печать',
      printJournalElement: 'Элементы',
      pdfAction: {
        action: 'Создать PDF',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      csvAction: {
        action: 'Создать CSV',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      loadAction: {
        action: 'Загрузиь',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      fields: {

        menge: 'Количество',
        companyUniqName: 'Компания в системе',
        creatorUsername: 'Имя создателя',
        gtin: 'GTIN',
        label: 'Этикетка',
        matnr: 'Материал',
        ean: 'EAN',
        inputhId: 'Id документа ввода',
        ordernum: 'Номер заказа',
        serialNumber: 'Серийный номер',
        emissionType: 'Тип эмиссии',
        mrkStatusAfterPrint: 'Внутренний статус',
        importPrint: 'Маркировка импорта',
        pdfPath: 'PDF путь',
        csvPath: 'CSV путь', 
        itemCode: 'Код товара',
      }
    }
  }
}