import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ELECTRONIC_DEVICE: 'Electronic device',
        PHYSICAL_DEVICE: 'Physical device',
      },
    }
  }
}