import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRODUCTION: 'Продукция',
        REMAINS: 'Остатки',
        IMPORT: 'Импорт',
        CROSSBORDER: 'Ввезен в РФ из стран ЕАЭС',
        REMARK: 'Перемаркировка',
      },
    }
  }
}
