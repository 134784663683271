import React from 'react';
import { Login as LoginRA } from 'react-admin';
import background from './auth-background.jpg'

const LoginPage = () => (
  <LoginRA
    backgroundImage={background}
  />
);

export default LoginPage;