import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Журнал печати (элементы)',
      fields: {
        goodsId: 'Id товара',
        label: 'Шаблон',
        mrkId: 'Id КМ',
        path: 'Путь',
        printJournalId: 'Id печати',
        gtin: 'GTIN',
        serialNumber: 'Серийный номер',
        pdfPath: 'PDF путь',
        csvPath: 'CSV путь',
        ean: 'EAN',
        inputDocnum: 'Номер документа ввод в оборот',
      },
    }
  }
}