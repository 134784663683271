import React from 'react';
import { SelectInput } from 'react-admin';

export default ({ enums, translatePrefix = null, ...restProps }) => {
  const choices = enums.map(x => {
    const result = {
      id: x,
      text: x,
    };
    if (translatePrefix !== null) {
      result.text = `${translatePrefix}${x}`;
    }
    return result;
  })

  return (
    <SelectInput 
      choices={choices}
      optionValue={'id'}
      optionText={'text'}
      translateChoice={translatePrefix !== null}
      {...restProps}
    />
  )
}