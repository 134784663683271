import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Склад |||| Склады',
      arrayInputLabel: 'Склады',
      setAsDefault: {
        action: 'По-умолчанию',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      fields: {
        default: 'По-умолчанию',
        'company.id': 'Компания',
        name: 'Название',
        uniqName: 'Уникальный ключ',
      }
    }
  }
}