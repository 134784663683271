import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'REQUEST_ERROR': 'Ошибка запроса',
        'REQUESTED': 'Запрос отправлен',
        'IN_PROCESS': 'В процессе',
        'READY': 'Готово',
        'CLOSED': 'Закрыто',
      },
    }
  }
}
