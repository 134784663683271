import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Лог КМ |||| Логи КМ',
      fields: {
        uit: 'Код маркировки',
        documentType: 'Тип документа',
        documentId: 'Id документа',
        status: 'Статус',
        operationDate: 'Дата операции',
        goodsBrand: 'Бренд',
        goodsEan: 'EAN',
        goodsName: 'Наименование',
        warehouseUniqName: 'Склад',
      },
    }
  }
}