import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'SHIPMENT': 'Отгрузка',
        'ACCEPTANCE': 'Приемка',
      },
    }
  }
}
