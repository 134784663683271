import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        EMITTED: 'Emitted',
        APPLIED: 'Applied',
        INTRODUCED: 'Introduced',
        RECYCLED: 'Recycled',
        RETIRED: 'Retried',
        RESERVED_NOT_USED: 'Reserved not used',
        INTRODUCED_RETURNED: 'Introduced returned',
        DISAGGREGATED: 'Disaggregated',
        WAIT_SHIPMENT: 'Whait shipment',
        EXPORTED: 'Exported',
        LOAN_RETIRED: 'Loadn retired',
        REMARK_RETIRED: 'Remark reired',
        READY_FOR_EXTERNAL_PROCESSING: 'Ready for external processing',
        OK: 'OK',
      },
    }
  }
}