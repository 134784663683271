import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Полномочия |||| Полномочия',
      arrayInputLabel: 'Полномочия',
      fields: {
        name: 'Название',
      }
    }
  }
}