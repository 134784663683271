

export default {
  enums: [
    { id: 'SALE' },
    { id: 'COMMISSION' },
    { id: 'AGENT' },
    { id: 'CONTRACT' },
    { id: 'SELLING' },
  ]
};