import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ELECTRONIC_DEVICE: 'В электронном виде',
        PHYSICAL_DEVICE: 'На физическом носителе',
      },
    }
  }
}
