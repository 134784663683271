import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CEM: 'CEM',
        SELF_MADE: 'Собственное производство',
      },
    }
  }
}
