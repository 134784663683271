import qs from 'qs';
import getAuthHeaders from './getAuthHeaders';
import getServiceUrl from './getServiceUrl';

export default async (options) => {
  let {
    body = null,
    urlParams = null,
    path = '/',
    method = 'GET',
    headers = {},
    apiService = null,
    warehouseId = null,
    productGroup = null,
    accessToken = null,
    responseType = 'json',
    contentType = null,
  } = options;

  const authHeaders = await getAuthHeaders({
    warehouseId,
    productGroup,
    accessToken,
  });

  headers = {
    ...headers,
    ...authHeaders,
  };

  let baseUrl = getServiceUrl(apiService);

  let fullUrl = `${baseUrl}${path}`;
  if (urlParams !== null) {
    fullUrl = `${fullUrl}?${qs.stringify(urlParams)}`;
  }

  if (contentType !== null && body !== null) {
    if (contentType === 'form' && typeof body === 'object') {
      const formData = new FormData();
      for (let key in body) {
        formData.append(key, body[key]);
      }

      body = formData;
    }
  }

  const requestConfig = {
    method,
    body,
    headers,
  };
  
  const response = await fetch(fullUrl, requestConfig);
  
  const result = {
    response,
    ok: response.ok,
    status: response.status,
    data: null,
  }
  try {
    if (responseType === 'json') {
      result.data = await response.json()
    }
    if (responseType === 'text') {
      result.data = await response.text()
    }
    if (responseType === 'blob') {
      result.data = await response.blob()
    }
  } catch(e) {}

  return result;
}