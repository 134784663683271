import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        '311': '311',
        '312': '312',
        '313': '313',
      },
    }
  }
}