import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'EAN инфо |||| EAN инфо',
      fields: {
        dateFrom: 'Дата с',
        dateTo: 'Дата по',
      }
    }
  }
}