import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Label template |||| Label templates',
      
      loadAction: {
        action: 'Load',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        createDate: 'Create date',
        updateDate: 'Update date',
      }
    }
  }
}