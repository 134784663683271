import React from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    // EditButton,
    List,
    SimpleForm,
    TextField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    EditButton,
    TextInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import good from '../good';
import treeMrk from '../treeMrk';
import printJournal from '../printJournal';
import printLoad from '../printLoad';
import exporter from '../../../../utils/exporter';

const ResourceIcon = BookmarkIcon;

const resourceToString = x => `#${x.id} (${x.docnum})`;

const listStyles = {
};


const ResourceListFilter = (props) => (
    <Filter {...props}>
        <printJournal.Input source={'printJournalId'} />
        <TextInput source={'ean'} />
        <TextInput source={'inputDocnum'} />
    </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        exporter={exporter}
    >
        <Datagrid>
            <TextField source={'id'} />
            <good.Field source={'goodsId'} />
            <TextField source={'label'} />
            <treeMrk.Field source={'mrkId'} />
            <TextField source={'path'} />
            <TextField source={'gtin'} />
            <TextField source={'serialNumber'} />
            <printJournal.Field source={'printJournalId'} />
            <printLoad.OpenButton type={'csv'} />
            <printLoad.OpenButton type={'pdf'} />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.name
        }&quot;
    </span>
));


const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <good.Input source={'goodsId'} />
            <TextInput source={'label'} />
            <treeMrk.Input source={'mrkId'} />
            <TextInput source={'path'} />
            <printJournal.Input source={'printJournalId'} />
            <TextInput source={'gtin'} />
            <TextInput source={'serialNumber'} />
            <TextInput source={'pdfPath'} />
            <TextInput source={'csvPath'} />
            <TextInput source={'ean'} />
            <TextInput source={'inputDocnum'} />
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm>
            <TextField source={'id'} />
            <good.Input source={'goodsId'} />
            <TextInput source={'label'} />
            <treeMrk.Input source={'mrkId'} />
            <TextInput source={'path'} />
            <printJournal.Input source={'printJournalId'} />
            <TextInput source={'gtin'} />
            <TextInput source={'serialNumber'} />
            <TextInput source={'pdfPath'} />
            <TextInput source={'csvPath'} />
            <TextInput source={'ean'} />
            <TextInput source={'inputDocnum'} />
        </SimpleForm>
    </Edit>
);


const ResourceStringField = ({ record }) => (
    <span>{resourceToString(record)}</span>
)

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <ResourceStringField />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={resourceToString} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={resourceToString}  />
    </ReferenceArrayInput>
);


export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
        }
    }
}
