import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Аккаунт |||| Аккаунты',
      fields: {
        'company.id': 'Компания',
        name: 'Имя',
        note: 'Описание',
        warehouses: 'Склады',
        productGroups: 'Товарные группы',
      }
    }
  }
}