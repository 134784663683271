import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CONSUMER': 'Потребитель',
        'GROUP': 'Группа',
        'TRANSPORTATION': 'Транспортировка',
      },
    }
  }
}
