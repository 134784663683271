import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'SALE': 'SALE',
        'COMMISSION': 'Комиссия',
        'AGENT': 'Агент',
        'CONTRACT': 'Контракт',
        'SELLING': 'Продажа',
      },
    }
  }
}
