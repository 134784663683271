export default (response) => {
  console.log(response);
  const result = {
    ...response,
    ...response.response,
    ...response.data
  };
  
  if (result.status === 401) {
    result.message = 'Authentication failed';
  } else if (result.exMessage) {
    const errorMatch = result.exMessage.match(/RuntimeException: ([^\n]*)/);
    if (errorMatch !== null && errorMatch[1]) {
      result.message = errorMatch[1];
    }
  }

  return result;
}