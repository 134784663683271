import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import appConfig from './appConfig';
import RootContainer from './components/RootContainer/RootContainer';
import auth from './auth'
import './index.css';
import './services';


appConfig.loadConfig().then(async() => {


  // Инициализируем кейклок
  const authorized = await auth.init();
  if (!authorized) {
    // Редирект на логин
    auth.login();
    return;
  }

  ReactDOM.render(
    <RootContainer />, 
    document.getElementById('root')
  );
});