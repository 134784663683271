import React, { Component, Fragment } from 'react';
import { Title } from 'react-admin';

import Welcome from './Welcome';

const styles = {
    singleCol: { maxWidth: 500 },
};

class Dashboard extends Component {
    state = {};

    render() {
        return (
            <Fragment>
                <Title title={'pos.title'} />
                
                <div style={styles.singleCol}>
                    <Welcome />
                </div>
            </Fragment>
        );
    }
}

export default Dashboard;
