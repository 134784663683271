import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UPLOADING_DOCUMENT: 'Загрузка',
        PROCESSING_DOCUMENT: 'Обработка',
        CORE_PROCESSING_DOCUMENT: 'Системная обработка',
        CORE_PROCESSED_DOCUMENT: 'Обработанно системой',
        PROCESSED_DOCUMENT: 'Обработан',
        FAILED: 'Отклонен',
        FAILED_RESULT_READY: 'Отклонен с ответом',
      },
    }
  }
}