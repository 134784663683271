import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Reaggregate',
      title: 'MRK reaggregation',
      executeAction: 'Reaggregate',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        sscc: 'SSCC',
        mrkIds: 'Items',
        participantId: 'Participant',
        aggrType: 'Aggrigation type'
      }
    }
  }
}