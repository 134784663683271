import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, showNotification, refreshView as refreshViewAction } from 'react-admin';

import request from '../../../../network/request';
import resourceConfig from './config';

class UpdateKmAllButton extends Component {
    state = {
      executing: false
    }
    handleClick = async() => {
        const { showNotification, refreshView } = this.props;

        this.setState({
          executing: true
        });
        let response = null;
        try {
          response = await request({
            apiService: 'shoes',
            path: '/admin/mrk/func/refresh_all',
            method: 'POST',
          })
        } catch (e) {
          console.error(e);
        }
        if (response === null || !response.ok) {
          showNotification(`resources.${resourceConfig.name}.updateAllKM.errorMessage`, 'error');
        } else {
          showNotification(`resources.${resourceConfig.name}.updateAllKM.successMessage`, 'success');
        }
        
        this.setState({
          executing: false
        }, refreshView);
    };
    

    render() {
        return (
            <Button size={'small'} color={'primary'} label={`resources.${resourceConfig.name}.updateAllKM.action`} disabled={this.state.executing} onClick={this.handleClick} />
        );
    }
}

export default connect(undefined, { 
  showNotification, 
  refreshView: refreshViewAction 
})(UpdateKmAllButton);