import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Лог фоновой задачи |||| Логи фоновых задач',
      fields: {
        regtime: 'Дата',
        taskManagerModelId: 'Задачи',
        info: 'Сообщение',
      }
    }
  }
}