import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Download',
      openActionPdf: 'Get pdf',
      openActionCsv: 'Get csv',
      title: 'Download',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
    }
  }
}