import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        FOREIGN: 'Импорт',
        LOCAL: 'Производство',
        REMAINS: 'Остатки',
        CROSSBORDER: 'Ввезен в РФ из стран ЕАЭС',
        REMARK: 'Перемаркировка',
      },
    }
  }
}
