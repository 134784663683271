import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Импорт из третьих стран',
      importThirdCountryP: 'Позиции',
      sendDoc: {
        action: 'Отправить',
      },
      checkDocs: {
        action: 'Проверить статус',
      },
      enums: {
        docType: {
          DECLARATION: 'DECLARATION',
          STATEMENT: 'STATEMENT',
        },
        status: {
          CHECKED_NOT_OK: 'CHECKED_NOT_OK',
          CHECKED_OK: 'CHECKED_OK',
        },
      },
      fields: {
        id: 'Id',
        fileId: 'GUID',
        sendingDateTime: 'Дата отправки',
        reason: 'Причина вывода товаров из оборота',
        participantName: 'Наименование УОТ',
        importerName: 'Наименование импортера',
        importerCode: 'ИИН/БИН получателя',
        exportCountry: 'Страна экспорта',
        docType: 'Тип документа',
        regNumber: 'Регистрационный номер документа',
        regDate: 'Дата регистрации документа',
        decisionCode: 'Код принятого решения',
        decisionDate: 'Дата принятого решения',
        customCode: 'Код таможенного органа',
        docTypeCertificate: 'Тип документа соответствия',
        docNumberCertificate: 'Номер документа соответствия',
        docDateCertificate: 'Дата документа соответствия',
        docNumberPaper: 'Номер бумажного документа соответствия',
        docDatePaper: 'Дата бумажного документа соответствия',
        docType: 'Тип документа',
        status: 'Статус ИС МПТ',
        dateSend: 'Дата отправки',
      },
    },
  },
};

// <TextInput source={'fileId'} />
// <DateInput source={'dateSend'} label={`resources.${resourceConfig.name}.fields.dateSend`} />
// <TextInput source={'importerName'} />
// <TextInput source={'importerCode'} />
// <TextInput source={'exportCountry'} />
// <EnumInput
//   source={'docType'}
//   translatePrefix={`${trPrefix}.enums.docType.`}
//   label={`${trPrefix}.fields.docType`}
//   enums={resourceConfig.enums.docType}
// />
// <TextInput source={'regNumber'} />
// <DateInput source={'regDate'} label={`resources.${resourceConfig.name}.fields.regDate`} />
// <NumberInput source={'decisionCode'} />
// <DateInput
//   source={'decisionDate'}
//   label={`resources.${resourceConfig.name}.fields.decisionDate`}
// />
// <TextInput source={'customCode'} />
// <TextInput source={'docTypeCertificate'} />
// <TextInput source={'docNumberCertificate'} />
// <DateInput
//   source={'docDateCertificate'}
//   label={`resources.${resourceConfig.name}.fields.docDateCertificate`}
// />
// <TextInput source={'docNumberPaper'} />
// <DateInput
//   source={'docDatePaper'}
//   label={`resources.${resourceConfig.name}.fields.docDatePaper`}
// />
// </SimpleForm>
