

export default {
  enums: [
    { id: 'REQUEST_ERROR' },
    { id: 'REQUESTED' },
    { id: 'IN_PROCESS' },
    { id: 'READY' },
    { id: 'CLOSED' },
  ]
};
