import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ListSubheader, ListItem, ListItemText, Typography, ListItemIcon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CategoryIcon from '@material-ui/icons/Category';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate } from 'react-admin';
import request from '../../network/request';
import appCore from '../../appCore';



const styles = {
  label: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  value: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
};

class ProductGroupSelect extends Component {
  constructor(props) {
    super(props);

    const value = appCore.params.productGroup || null;

    this.state = {
      loading: true,
      allItems: [],
      anchorEl: null,
      value,
    }
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    let allItems = [];
    let defaultUniqName = null;
    try {
      const [responseAll, responseDefault] = await Promise.all([
        request({
          apiService: 'account',
          path: '/current/pg',
          method: 'GET',
        }),
        request({
          apiService: 'account',
          path: '/current/pg/default',
          method: 'GET',
          responseType: 'text',
        }),
      ]);
      if (responseAll !== null && responseAll.ok) {
        allItems = responseAll.data.map(x => ({ uniqName: x, title: x }));
      }  
      if (responseDefault !== null && responseDefault.ok) {
        defaultUniqName = responseDefault.data;
      }  
    } catch (e) {
      console.error(e);
    }

    this.setState((state) => {
      let value = state.value;
      const existItem = allItems.find(x => x.uniqName === value);
      if (existItem === undefined) {
        value = null;
      }
      if (value === null) {
        value = defaultUniqName;
      }
      return {
        value,
        loading: false,
        allItems,
      };
    });
  }
  
  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  
  getLabel = () => {
    const {
      translate,
    } = this.props;
    if (this.state.value === null) {
      return translate('ProductGroupSelect.placeholder');
    }
    const item = this.state.allItems.find(x => x.uniqName === this.state.value);
    if (item === undefined) {
      return translate(`productGroups.${this.state.value}.name`, { _: this.state.value });
    }

    return translate(`productGroups.${item.title}.name`);
  }

  onSelect = (event) => {
    const value = event.currentTarget.dataset.uniqName || null;
    if (value === this.state.value) {
      this.setState({
        anchorEl: null,
      });
      return;
    }

    appCore.updateParams({ productGroup: value });

    this.setState({
      value,
      anchorEl: null,
    });

    window.location.reload();
  }

  render() {
    const {
      translate,
      classes,
    } = this.props;
    const {
      loading,
      allItems,
    } = this.state;

    return (
      <div>
        <ListItem
          button
          dense
          alignItems="center"
          justifyContent="space-between"
          aria-controls="productGroup-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
          style={{ paddingTop: 0, paddingBottom: 0, justifyContent: 'space-between', textAlign: 'left' }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={(
              <Typography variant="body2" display="block" color="textSecondary" className={classes.label}>
                {translate('ProductGroupSelect.label')}
              </Typography>
            )}
            secondary={(
              <Typography variant="body1" display="block"  color="textPrimary" className={classes.value}>
                {this.getLabel()}
              </Typography>
            )}
          />
          <ListItemIcon style={{ justifyContent: 'flex-end', minWidth: 35 }}>
            <ExpandMoreIcon />
          </ListItemIcon>
        </ListItem>
        <Menu
          id="productGroup-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <ListSubheader>{translate('ProductGroupSelect.hint')}</ListSubheader>
          {(allItems.length === 0 && loading) && ('...')}
          {(allItems.length === 0 && !loading) && (
            <MenuItem
              disabled
            >
              {translate('ProductGroupSelect.noGroups')}
            </MenuItem>
          )}
          {allItems.map((item) => (
            <MenuItem
              onClick={this.onSelect}
              data-uniq-name={item.uniqName}
              key={item.uniqName}
            >
              {translate(`productGroups.${item.title}.name`)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(translate(ProductGroupSelect));
