import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Файл |||| Файлы',
      fields: {
        inputhId: 'Id документа ввода',
        quantity: 'Количество',
        remainder: 'Расхождение',
        ids: 'Id',
        inputHId: 'Ввода в оборот',
      }
    }
  }
}