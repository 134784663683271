import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ошибка |||| Журнал ошибок',
      createOrder: {
      },
      fields: {
        createdDate: 'Дата создания',
        shortMessage: 'Краткое сообщение',
        message: 'Сообщение',
        functionType: 'Тип функции',
        httpClientMess: 'http', 
        ids: 'Id',
        documentLinkType: 'Тип документа',
        documentLinkId: 'Id документа',
      }
    }
  }
}