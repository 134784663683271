import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Order H',
      orderP: 'P records',
      fields: {
        id: '#',
        contractDate: 'Contract date',
        cemContractDate: 'Cem contract date',
        processedDate: 'Processed date',
        dateCreate: 'Date create',
      },
      filters: {
        dateCreateFrom: 'Create date from',
        dateCreateTo: 'Create date to',
      },
      orderSendList: {
        action: 'Send orders',
        successMessage: 'Success',
        errorMessage: 'Error',
      },

      checkKMList: {
        action: 'Check KM',
        successMessage: 'Success',
        errorMessage: 'Error',
      },

      getKMList: {
        action: 'Get KM',
        successMessage: 'Success',
        errorMessage: 'Error',
      },

      closeOrders: {
        action: 'Close ready orders',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
    },
  },
};
