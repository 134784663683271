export default {
  enums: [
    { id: 'GOODS_LOAD_TASK' },
    { id: 'CLOSE_READY_ORDER_TASK' },
    { id: 'CHECK_ORDER_STATUS_TASK' },
    { id: 'SEND_ACTIVE_ORDER_TASK' },
    { id: 'CHECK_MRK_STATUS_FROM_ORDER_TASK' },
    { id: 'GET_MRK_FROM_ACTIVE_ORDER_TASK' },
    { id: 'DOCUMENT_CHECKER_TASK' },
  ],
};
