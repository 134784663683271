import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UPLOADING_DOCUMENT: 'Uploading ',
        PROCESSING_DOCUMENT: 'Processing',
        CORE_PROCESSING_DOCUMENT: 'Core processing',
        CORE_PROCESSED_DOCUMENT: 'Core processed',
        PROCESSED_DOCUMENT: 'Processed',
        FAILED: 'Failed',
        FAILED_RESULT_READY: 'Failed result ready',
      },
    }
  }
}