

export default {
  enums: [
    { id: 'CANCELLATION_APPLIED', },
    { id: 'REMARK_RETIRED', },
    { id: 'RETIRED_CANCELLATION', },
    { id: 'DONATION', },
    { id: 'STATE_ENTERPRISE', },
    { id: 'NO_RETAIL_USE', },
    { id: 'BEYOND_EEC_EXPORT', },
    { id: 'СREMOTE_SALE', },
    { id: 'EEC_EXPORT', },
    { id: 'СRETURN', },
    { id: 'DAMAGE_LOSS', },
    { id: 'DESTRUCTION', },
    { id: 'CONFISCATION', },
    { id: 'LIQUIDATION', },
  ]
};
