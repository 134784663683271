import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  pos: {
    title: 'Marking service',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      welcome: {
        title: 'Welcome to marking services',
        subtitle: '',
      },
    },
    login: {
      hint: 'Signin to your account',
    },
  },
  resources: {},
  WarehouseSelect: {
    placeholder: 'Select warehouse',
    default: 'Default warehouse',
    noWarehouses: 'No available warehouses',
  },
  ProductGroupSelect: {
    label: 'Product group',
    hint: 'Available groups',
    placeholder: 'Select group',
    default: 'Default group',
    noGroups: 'No available groups',
  },
  changePassowrdPage: {
    title: 'Change password',
    errorMessage: 'Error',
    successMessage: 'Success',
    fields: {
      password: 'Current password',
      newPassword: 'New password',
    },
  },
  searchKMPage: {
    title: 'Search KM',
    resultTitle: 'Search result',
    errorMessage: 'Response error',
    invalidResponseMessage: 'Invalid response format',
    noCodesFoundMessage: 'Codes not found',
    validationMessage: {
      uitLengthMin21: 'You must specify at least 21 characters in the UIT field',
      serialNumberLengthMin5: 'You must specify at least 5 characters in the serialNumber field',
      gtinLengthMin14: 'Must enter at least 14 characters in the GTIN field',
      serialNumberRequired: 'You must additionally specify a value in the serialNumber field',
    },
    fields: {
      cis: 'Cis code',
      sgtin: 'Sgtin',
      gtin: 'GTIN',
    },
  },
  menu: {
    serviceApiDoc: 'API documentation',
  },
  productGroups: {
    clothes: {
      name: 'Clothes',
    },
    shoes: {
      name: 'Shoes',
    },
    tobacco: {
      name: 'Tobacco',
    },
    perfumery: {
      name: 'Perfumery',
    },
    tires: {
      name: 'Tires',
    },
    electronics: {
      name: 'Electronics',
    },
    pharma: {
      name: 'Pharma',
    },
    milk: {
      name: 'Milk',
    },
    bicycle: {
      name: 'Bicycle',
    },
    wheelchairs: {
      name: 'Wheelchairs',
    },
  },
};
