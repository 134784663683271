import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Jasper',
      title: 'Jasper',
      executeAction: 'Подтвердить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
    }
  }
}