import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товарная группа |||| Товарные группы',
      arrayInputLabel: 'Товарные группы',
      setAsDefault: {
        action: 'Сделать по-умолчанию',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      fields: {
        'company.id': 'Компания',
        default: 'По-умолчанию',
        uniqName: 'Товарная группа',
      },
    }
  }
}