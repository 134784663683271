import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN: 'In',
        OUT: 'Out',
        '1': 'In',
        '2': 'Out',
      },
    }
  }
}