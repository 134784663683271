export default (document, { translate = x => x, showNotification = null } = {}) => {
  let errorMessage = null;
  // Доп. проверка на перемещение между складами
  if (document.checkStatus === 'CHECKED_OK' && !(document.warehouseFrom && document.warehouseTo)) {
    errorMessage = translate('documentsSendError.success', { id: document.id })
  }
  if (['SENDING_DOC', 'SENT_DOCUMENT', 'WAITING_STATUS'].includes(document.documentSystemStatus)) {
    errorMessage = translate('documentsSendError.sending', { id: document.id })
  }

  if (errorMessage !== null && showNotification) {
    showNotification(errorMessage, 'error');
  }

  return errorMessage === null;
}