import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Из док-а',
      title: 'Загрузка товаров из документа',
      executeAction: 'Загрузить',
      errorMessage: 'Ошибка',
      successMessage: 'Количество обновленных записей: %{count}',
      updatedItemsCount: 'Кол-во обновленных записей',
      notFoundGtins: 'Не найденные GTIN',
      fields: {
      },
    }
  }
}