import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';

const TransitionLink = ({ transitionTo = {}, location, ...props }) => {
  const locationParsed = {
    ...location
  };
  if (locationParsed.search) {
    locationParsed.search = qs.parse(locationParsed.search.slice(1));
  }
  const newLocation = {};
  if (transitionTo.params) {
    newLocation.search = transitionTo.params;
  }
  const merged = {
    ...locationParsed,
    ...newLocation,
    search: {
      ...locationParsed.search,
      ...newLocation.search,
    }
  };
  if (merged.search) {
    merged.search = `?${qs.stringify(merged.search)}`;
  }

  // console.log(location)

  return (
    <Link
      {...props}
      to={merged}
    />
  )
};

export default withRouter(TransitionLink);
