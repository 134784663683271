import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRODUCT: 'Товар',
        PRODUCT_PACKAGE: 'Упаковка',
        PRODUCT_LABEL: 'Этикетка',
      },
    }
  }
}