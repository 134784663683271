import React, { Fragment } from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    BulkDeleteButton,
} from 'react-admin';
import DateInput from '../../../../components/DateInput';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import LinkToList from './LinkToList';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import printTicketUpload from '../printTicketUpload';
import printLoad from '../printLoad';
import exporter from '../../../../utils/exporter';

const ResourceIcon = BookmarkIcon;

const listStyles = {
};

const ResourceBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
        <TextInput source={'ids'} />
    </Filter>
);



const UploadButton = (props) => {
    return (
        <printTicketUpload.OpenButton
            size={'small'}
            params={{
                labelId: props.record.id
            }}
        />
    );
}


const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        bulkActionButtons={<ResourceBulkActionButtons />}
        exporter={exporter}
    >
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'} />

            <TextField source={'name'} />
            <TextField source={'type'} />
            <TextField source={'status'} />

            
            <UploadButton />
            <printLoad.OpenButton />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.name
        }&quot;
    </span>
));


const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source={'companyUniqName'} /> */}
            <DateInput source={'createDate'} label={`resources.${resourceConfig.name}.fields.createDate`} />
            {/* <TextInput source={'creatorUsername'} /> */}
            <TextInput source={'defaultTemplate'} />
            <TextInput source={'guid'} />
            <TextInput source={'name'} />
            <TextInput source={'path'} />
            <TextInput source={'status'} />
            <TextInput source={'statusChangeDescription'} />
            <TextInput source={'type'} />
            <DateInput source={'updateDate'} label={`resources.${resourceConfig.name}.fields.updateDate`} />
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm>
            <TextField source={'id'} />
            {/* <TextInput source={'companyUniqName'} /> */}
            <DateInput source={'createDate'} label={`resources.${resourceConfig.name}.fields.createDate`} />
            {/* <TextInput source={'creatorUsername'} /> */}
            <TextInput source={'defaultTemplate'} />
            <TextInput source={'guid'} />
            <TextInput source={'name'} />
            <TextInput source={'path'} />
            <TextInput source={'status'} />
            <TextInput source={'statusChangeDescription'} />
            <TextInput source={'type'} />
            <DateInput source={'updateDate'} label={`resources.${resourceConfig.name}.fields.updateDate`} />
        </SimpleForm>
    </Edit>
);

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <TextField source={'id'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={'id'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
);



export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,
    
    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name, 
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
            createDate: {
                type: 'dateTime',
            },
            updateDate: {
                type: 'dateTime',
            },
        },
        filters: {
            createDate: {
                type: 'dateTime',
            },
            updateDate: {
                type: 'dateTime',
            },
        }
    }
}
