import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Фоновая задача |||| Фоновые задачи',
      errors: 'Ошибки',
      logs: 'Логи',
      fields: {
        active: 'Активен',
        name: 'Имя',
        periodType: 'Тип периода',
        periodValue: 'Значение периода',
        cron: 'CRON',
        milliseconds: 'Значение периода',
        taskType: 'Тип задачи',
        params: 'Параметры',
        running: 'Запущен',
      }
    }
  }
}