import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        EMITTED: 'Эмитирован',
        APPLIED: 'Нанесен',
        INTRODUCED: 'Введен',
        RECYCLED: 'Переработан',
        RETIRED: 'Выбыл',
        RESERVED_NOT_USED: 'Зарезервировано. Не использовать',
        INTRODUCED_RETURNED: 'Возвращён в оборот',
        DISAGGREGATED: 'Дезагрегирован',
        WAIT_SHIPMENT: 'Ожидает отгрузки',
        EXPORTED: 'Используется для документов экспорта',
        LOAN_RETIRED: 'Выведен из оборота по договору рассрочки',
        REMARK_RETIRED: 'Выведен из оборота при перемаркировке',
        READY_FOR_EXTERNAL_PROCESSING: 'Готов для внешней обработки',
        OK: 'OK',
      },
    }
  }
}
