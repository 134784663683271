import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'MC move log |||| MC move logs',
      fields: {
      },
    }
  }
}