export default {
  enums: [
    { id: 'GOODS' },
    { id: 'ORDER' },
    { id: 'OUT' },
    { id: 'TREEMRK' },
    { id: 'ORDER_P' },
    { id: 'IMPORT_THIRD_COUNTRY' },
  ],
};
