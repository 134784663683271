import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'NEW': 'документ создан',
        'SENDING_DOC': 'отправка документа',
        'SENT_DOCUMENT': 'документ отправлен',
        'LOAD_DOCUMENT': 'документ загружен',
        'ERROR': 'ошибка',
        'CHECK_TICKET': 'проверка запроса',
        'BAD_TICKET': 'ошибка запроса',
        'WAITING_STATUS': 'получение статуса',
      },
    }
  }
}