import moment from 'moment';
import request from './request';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  UPDATE_MANY,
  CREATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import get from 'lodash/get';
import set from 'lodash/set';
import parseServerError from './parseServerError';
import appConfig from '../appConfig';

let resourceResolver = {};

export const registerResource = (res) => {
  resourceResolver[res.name] = res;
};

const dateTimeToParam = (data) => {
  let result = data;
  let m = moment(data, 'DD.MM.YYYY');
  if (m.isValid()) {
    result = m.format(appConfig.data.apiDateTimeFormat);
  }
  m = moment(data);
  if (m.isValid()) {
    result = m.format(appConfig.data.apiDateTimeFormat);
  }
  return result;
};

const dateToParam = (data) => {
  let result = data;
  let m = moment(data, 'DD.MM.YYYY');
  if (m.isValid()) {
    result = m.format(appConfig.data.apiDateFormat);
  }
  m = moment(data);
  if (m.isValid()) {
    result = m.format(appConfig.data.apiDateFormat);
  }
  return result;
};

export const restProviderHandle = async (type, resource, params, ...a) => {
  console.log(type, resource, params);
  let basePath = `/${resource}`;
  const requestConfig = {};
  let urlParams = {};
  const resolver = resource in resourceResolver ? resourceResolver[resource] : null;
  if (resolver !== null) {
    if ('basePath' in resolver) {
      basePath = resolver.basePath;
    }
    if ('apiService' in resolver) {
      requestConfig.apiService = resolver.apiService;
    }
  }

  if (type === CREATE || type === UPDATE) {
    requestConfig.method = type === CREATE ? 'POST' : 'PATCH';
    requestConfig.headers = {
      ...requestConfig.headers,
      'Content-Type': 'application/json',
    };

    let data = { ...params.data };
    if (resolver !== null && resolver.mapping && resolver.mapping.outputItem) {
      data = resolver.mapping.outputItem(data);
    }
    if (resolver !== null && resolver.schema) {
      // TODO: данная реализация не работает с вложенными объектами (напрм. UPD_H.additional)
      for (let key in resolver.schema) {
        const field = resolver.schema[key];
        let value = get(data, key);
        if (value !== null) {
          if (field.type === 'dateTime' && value !== null && value !== undefined) {
            value = dateTimeToParam(value);
          } else if (field.type === 'date' && value !== null && value !== undefined) {
            value = dateToParam(value);
          } else if (
            field.type === 'string' &&
            value !== undefined &&
            value !== null &&
            typeof value !== 'string'
          ) {
            value = value.toString();
          }
          set(data, key, value);
        }
      }
    }

    requestConfig.body = JSON.stringify(data);
  }

  if (type === DELETE) {
    basePath = `${basePath}/${params.id}`;
    requestConfig.method = 'DELETE';
  }
  if (type === DELETE_MANY) {
    throw new Error(`${DELETE_MANY} method is not supported`);
  }
  if (type === GET_ONE || type === UPDATE) {
    basePath = `${basePath}/${params.id}`;
  }
  if (type === GET_LIST || type === GET_MANY_REFERENCE) {
    basePath = `${basePath}/search`;
    if ('listPath' in resolver) {
      basePath = resolver.listPath;
    }
    if (type === GET_LIST) {
      if (params.sort && resolver !== null && resolver.schema) {
        if (
          params.sort.field in resolver.schema &&
          resolver.schema[params.sort.field].sortingField
        ) {
          params.sort.field = resolver.schema[params.sort.field].sortingField;
        }
      }

      urlParams.size = params.pagination.perPage;
      urlParams.page = params.pagination.page - 1;
      if (params.sort && params.sort.field && params.sort.order) {
        urlParams.sort = `${params.sort.field},${params.sort.order.toLowerCase()}`;
      }
      if (resolver.disableSort) {
        delete urlParams.sort;
      }
    }
    const filter = {};
    if (params.filter) {
      for (let key in params.filter) {
        let value = params.filter[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        filter[key] = value;
      }
    }

    if (resolver !== null && (resolver.schema || resolver.filters)) {
      for (let key in filter) {
        let paramConfig = null;
        if (resolver.filters && key in resolver.filters) {
          paramConfig = resolver.filters[key];
        } else if (resolver.schema && key in resolver.schema) {
          paramConfig = resolver.schema[key];
        }

        if (paramConfig !== null) {
          if (paramConfig.type === 'dateTime') {
            filter[key] = dateTimeToParam(filter[key]);
          } else if (paramConfig.type === 'date') {
            filter[key] = dateToParam(filter[key]);
          } else if (
            paramConfig.type === 'string' &&
            filter[key] !== undefined &&
            filter[key] !== null &&
            typeof filter[key] !== 'string'
          ) {
            filter[key] = filter[key].toString();
          }
        }
      }
    }

    urlParams = {
      ...urlParams,
      ...filter,
    };
  }

  if (type !== DELETE && type !== DELETE_MANY && params.ids) {
    urlParams.ids = params.ids.join(',');
  }

  let result = null;

  if (resolver !== null && resolver.getList && type === GET_LIST) {
    return await resolver.getList(params);
  } else if (resolver !== null && resolver.getMany && type === GET_MANY) {
    return await resolver.getMany(params);
  } else if (resolver !== null && resolver.getList && type === GET_MANY_REFERENCE) {
    return await resolver.getManyReference(params);
  } else if (resolver !== null && resolver.getAllItems) {
    const allItems = await resolver.getAllItems(params);
    if (type === GET_LIST || type === GET_MANY_REFERENCE || type === GET_MANY) {
      result = {
        data: allItems,
        total: allItems.length,
      };
    }
    return result;
  }

  try {
    const response = await request({
      body: requestConfig.body,
      urlParams,
      path: basePath,
      method: requestConfig.method,
      headers: requestConfig.headers,
      apiService: requestConfig.apiService,
      responseType: 'json',
    });

    if (!response.ok) {
      const error = parseServerError(response);
      console.log(error);
      throw error;
    }
    if (type === GET_LIST || type === GET_MANY_REFERENCE) {
      result = {
        data: response.data.content,
        total: response.data.totalElements,
        perPage: response.data.size,
      };
      if (resolver.mapping && resolver.mapping.outputList) {
        result = {
          ...result,
          ...resolver.mapping.outputList(result),
        };
      }
    } else if (type === GET_MANY) {
      result = {
        data: response.data.content,
      };
    } else if (type === DELETE) {
      result = {
        data: {
          id: params.id,
          deleted: response.data,
        },
      };
    } else {
      result = {
        data: response.data,
      };
    }
  } catch (e) {
    throw e;
  }

  const processItem = (item) => {
    if (resolver !== null && resolver.idField) {
      item.id = item[resolver.idField];
    }
    if (resolver.mapping && resolver.mapping.inputItem) {
      item = resolver.mapping.inputItem(item);
    }
    return item;
  };

  // Process each item
  if (Array.isArray(result.data)) {
    result.data = result.data.map(processItem);
  } else {
    result.data = processItem(result.data);
  }
  return result;
};

// eslint-disable-next-line
const exportAll = async (resource, params) => {
  const result = {
    data: [],
    total: null,
  };
  let limit = 2000;
  let perPage = 200;
  let startPage = 0;
  let endPage = startPage + Math.ceil(limit / perPage - 1);
  let maxCount = limit; // На случай нечетного кол-ва

  const calcData = await restProviderHandle(GET_LIST, resource, {
    ...params,
    pagination: { perPage: 1, page: 1 },
  });

  if (calcData.total > limit) {
    const userCount = parseInt(
      prompt(
        `Кол-во записей больше ${limit}. Укажите кол-во записей которое вы хотите выгрузить (кратно ${perPage})`,
        limit,
      ),
    );
    const userOffset = parseInt(
      prompt(`Укажите кол-во записей, которое вы хотите пропустить (кратно ${perPage})`, startPage),
    );

    if (isNaN(userCount) || userCount <= 0 || isNaN(userOffset) || userOffset < 0) {
      throw new Error('Export canceled');
    }

    maxCount = userCount;
    startPage = Math.floor(userOffset / perPage);
    endPage = startPage + Math.ceil(userCount / perPage - 1);
  }

  let page = startPage;

  const loop = async () => {
    const loopParams = {
      ...params,
      pagination: {
        perPage,
        page: page + 1,
      },
    };
    const data = await restProviderHandle(GET_LIST, resource, loopParams);
    if (result.total === null) {
      result.total = data.total;
    }
    result.data = [...result.data, ...data.data];

    if (page < endPage && (startPage - 1) * perPage + result.data.length < result.total) {
      page++;
      return await loop();
    }
  };

  await loop();

  if (result.data.length > maxCount) {
    result.data = result.data.slice(0, maxCount);
  }

  return result;
};

export default {
  getList: (resource, params) => {
    if (params.pagination.perPage === 1000) {
      return exportAll(resource, params);
    }
    return restProviderHandle(GET_LIST, resource, params);
  },
  getOne: (resource, params) => restProviderHandle(GET_ONE, resource, params),
  getMany: (resource, params) => restProviderHandle(GET_MANY, resource, params),
  getManyReference: (resource, params) => restProviderHandle(GET_MANY_REFERENCE, resource, params),
  update: (resource, params) => restProviderHandle(UPDATE, resource, params),
  updateMany: (resource, params) => restProviderHandle(UPDATE_MANY, resource, params),
  create: (resource, params) => restProviderHandle(CREATE, resource, params),
  delete: (resource, params) => restProviderHandle(DELETE, resource, params),
  deleteMany: async (resource, params) => {
    const promises = params.ids.map((id) => restProviderHandle(DELETE, resource, { id }));

    const response = await Promise.allSettled(promises).then((responses) => {
      const deletedItems = responses.filter(
        (x) => x.status === 'fulfilled' && x.value.data.deleted,
      );
      const data = deletedItems.map((x) => x.value.data.id);
      return {
        data,
      };
    });
    return response;
  },
};
