import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'NEW': 'NEW',
        'SENDING_DOC': 'SENDING_DOC',
        'SENT_DOCUMENT': 'SENT_DOCUMENT',
        'LOAD_DOCUMENT': 'LOAD_DOCUMENT',
        'ERROR': 'ERROR',
        'CHECK_TICKET': 'CHECK_TICKET',
        'BAD_TICKET': 'BAD_TICKET',
        'WAITING_STATUS': 'WAITING_STATUS',
      },
    }
  }
}