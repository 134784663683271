import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'SELF_MADE': 'Собственное производство',
        'OPERATOR': 'Оператор',
      },
    }
  }
}