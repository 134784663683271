import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        FOREIGN: 'Foreign',
        LOCAL: 'Local',
        REMAINS: 'Remains',
        CROSSBORDER: 'Crossborder',
        REMARK: 'Remark',
      },
    }
  }
}