import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'ALL': 'All',
        'UNIT': 'Unit',
        'PACK': 'Pack',
      },
    }
  }
}





