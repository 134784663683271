import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PRODUCTION: 'Production',
        REMAINS: 'Remains',
        IMPORT: 'Import',
        CROSSBORDER: 'Crossborder',
        REMARK: 'Remark',
      },
    }
  }
}