import React from 'react';
import { connect } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './components/AppBar';
import { darkTheme, lightTheme } from './themes';

const CustomSidebar = (props) => <Sidebar {...props} />;
const CustomLayout = (props) => <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />;

export default connect(
  (state) => ({
    theme: state.app.theme === 'dark' ? darkTheme : lightTheme,
  }),
  {},
)(CustomLayout);
