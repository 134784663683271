export default {
  enums: [
    { id: 'NEW' },
    { id: 'IN_DOC_NOT_YET_EXEC' },
    { id: 'MOVE_DESTRUCTION' },
    { id: 'DESTRUCTION' },
    { id: 'WITHDRAWAL' },
    { id: 'PRINTED' },
    { id: 'NOT_ARRIVED' },
    { id: 'TRANSIT_2_SORT' },
    { id: 'TRANSIT_DAMAGED' },
    { id: 'W_RETIREMENT' },
    { id: 'OUT_OF_CIRCULATION' },
    { id: 'W_INTRODUCING' },
    { id: 'INTRODUCED' },
    { id: 'W_SHIPMENT' },
    { id: 'SHIPPED' },
    { id: 'PRODUCTION' },
    { id: 'USED' },
    { id: 'W_CANCELLATION' },
    { id: 'ORDER_RESERVED' },
    { id: 'IN_SET' },
    { id: 'W_RETURN' },
    { id: 'COMPLETED' },
    { id: 'MOVED' },
    { id: 'W_REMARK' },
  ],
};
