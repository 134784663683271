import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Права |||| Права',
      fields: {
        path: 'Путь',
        authorityId: 'Полномочия',
        description: 'Описание',
      }
    }
  }
}