export default {
  enums: [
    { id: 'IN_PROGRESS' },
    { id: 'CHECKED_OK' },
    { id: 'CHECKED_NOT_OK' },
    { id: 'PROCESSING_ERROR' },
    { id: 'UNDEFINED' },
    { id: 'CANCELLED' },
    { id: 'ACCEPTED' },
    { id: 'WAIT_ACCEPTANCE' },
    { id: 'WAIT_PARTICIPANT_REGISTRATION' },
    { id: 'PENDING' },
    { id: 'ACTIVE' },
    { id: 'EXHAUSTED' },
    { id: 'REJECTED' },
    { id: 'CLOSED' },
    { id: 'ERROR' },
    { id: 'NK_REQUEST_ERROR' },
  ],
};
