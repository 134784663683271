import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'XML документ',
      title: 'Получить XML документ',
      executeAction: 'Получить',
      errorMessage: 'Ошибка',
    }
  }
}