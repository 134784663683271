import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Лог передвижения КМ |||| Логи передвижения КМ',
      fields: {
        uit: 'Код маркировки',
        documentType: 'Тип документа',
        documentId: 'Id документа',
        status: 'Статус',
        operationDate: 'Дата операции',
        goodsBrand: 'Бренд',
        goodsEan: 'EAN',
        goodsName: 'Наименование',
        warehouseUniqName: 'Склад',

        aggrMId: 'Id aгреграции',
        gtin: 'GTIN',
        info: 'Инфо',
        mcMovementLogsId: 'Id лога',
        mrkId: 'ID КМ',
        mrkStatus: 'Статус маркировки',
        uits: 'Код маркировки',
      },
    }
  }
}