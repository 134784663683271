import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import SecurityIcon from '@material-ui/icons/Security';
import WarehouseSelect from '../WarehouseSelect';
import { Typography } from '@material-ui/core';

const styles = {
    userMenuWrap: {
        display: 'flex',
        alignItems: 'center',
    },
    userMenuItem: {
        marginLeft: 10
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
};


const CustomUserMenu = withStyles(styles)(translate(({ translate, classes, ...props }) => (
    <div className={classes.userMenuWrap}>
        <div className={classes.userMenuItem}>
            <WarehouseSelect />
        </div>
        <div className={classes.userMenuItem}>
            <UserMenu {...props}>
                <MenuItemLink
                    to="/change-password"
                    primaryText={translate('changePassowrdPage.title')}
                    leftIcon={<SecurityIcon />}
                />
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                />
            </UserMenu>
        </div>
    </div>
)));

const CustomAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
    </AppBar>
));

export default CustomAppBar;
