export default {
  enums: [
    { id: 'UNIT' },
    { id: 'LEVEL1' },
    { id: 'LEVEL2' },
    { id: 'LEVEL3' },
    { id: 'LEVEL4' },
    { id: 'LEVEL5' },
    { id: 'BUNDLE' },
    { id: 'SET' },
  ],
};
