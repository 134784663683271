import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Order M |||| Order M records',
      fields: {
        id: 'ID',
      },
    },
  },
};
