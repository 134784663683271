import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'SALE': 'SALE',
        'COMMISSION': 'COMMISSION',
        'AGENT': 'AGENT',
        'CONTRACT': 'CONTRACT',
        'SELLING': 'SELLING',
      },
    }
  }
}
