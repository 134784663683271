import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {
  translate,
  Title,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification,
  TextInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import request from '../../network/request';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
};

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar style={styles.toolbar} {...props}>
    <SaveButton redirect={false} submitOnEnter={true} label="search" />
    {/* {props.saving &&
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    } */}
  </Toolbar>
));

class SearchKMPage extends Component {
  state = {
    requestKey: Math.random(),
    loading: false,
    items: [],
  };

  getValidateMessage = (formData) => {
    const { uit, serialNumber, gtin } = formData;

    if (uit) {
      if (uit.length < 21) {
        return 'searchKMPage.validationMessage.uitLengthMin21';
      }
    } else {
      if (serialNumber && serialNumber.length < 5) {
        return 'searchKMPage.validationMessage.serialNumberLengthMin5';
      }
      if (gtin && gtin.length < 14) {
        return 'searchKMPage.validationMessage.gtinLengthMin14';
      }
      if (gtin && !serialNumber) {
        return 'searchKMPage.validationMessage.serialNumberRequired';
      }
    }
    return null;
  };

  execute = async (formData) => {
    const validationMessage = this.getValidateMessage(formData);
    if (validationMessage !== null) {
      this.props.showNotification(validationMessage, 'error');
      return;
    }

    this.setState({
      loading: true,
    });
    let data = {
      ...formData,
    };
    let response = null;
    try {
      response = await request({
        apiService: 'marking',
        path: '/admin/treemrkkz/customSearch',
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (e) {
      console.error(e);
    }
    let items = [];
    if (response !== null && response.status === 400) {
      this.props.showNotification(response.data.message, 'error');
    } else if (response === null || !response.ok) {
      this.props.showNotification('searchKMPage.errorMessage', 'error');
    } else if (Array.isArray(response.data)) {
      if (response.data.length === 0) {
        this.props.showNotification('searchKMPage.noCodesFound');
      } else {
        items = response.data;
      }
    } else {
      this.props.showNotification('searchKMPage.invalidResponseMessage', 'error');
    }
    this.setState({
      // requestKey: Math.random(),
      items,
      loading: false,
    });
  };

  render() {
    const { translate, classes } = this.props;
    const { requestKey, loading, items } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} spacing={2}>
          <Card>
            <Title title={translate('searchKMPage.title')} />
            <SimpleForm
              save={this.execute}
              toolbar={<FormToolbar />}
              saving={loading}
              key={requestKey}>
              <Typography variant={'h5'}>{translate('searchKMPage.title')}</Typography>

              <TextInput source="cis" label="searchKMPage.fields.cis" />
              <TextInput source="sgtin" label="searchKMPage.fields.sgtin" />
              <TextInput source="gtin" label="searchKMPage.fields.gtin" />
            </SimpleForm>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4} spacing={2}>
          {items.length > 0 && (
            <Card className={classes.root}>
              <CardHeader title={translate('searchKMPage.resultTitle')} />
              <List dense={true}>
                {items.map((record) => {
                  const url = `/marking--treeMrkkz/${record.id}`;
                  return (
                    <ListItem
                      key={record.id}
                      button={url !== null}
                      disabled={url === null}
                      component={url === null ? undefined : Link}
                      to={url || undefined}>
                      <ListItemText
                        style={{ flexShrink: 0, flexGrow: 1 }}
                        primary={record.uitCode}
                        secondary={
                          <p>
                            <div>Компания: {record.companyUniqName}</div>
                            <div>Склад: {record.warehouseUniqName}</div>
                            <div>Системный статус: {record.mrkSystemStatus}</div>
                            <div>Cтатус: {record.status}</div>
                          </p>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  connect(null, { showNotification }),
  translate,
  withStyles(styles),
)(SearchKMPage);
