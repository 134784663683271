import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'ALL': ' Товары и упаковки',
        'UNIT': 'Только товары',
        'PACK': 'Только упаковки',
      },
    }
  }
}
