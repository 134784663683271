import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DONATION: 'Безвозмездная передача',
        STATE_ENTERPRISE: 'Приобретение гос.предприятием',
        NO_RETAIL_USE: 'Использование для собственных нужд покупателем',
      },
    }
  }
}
