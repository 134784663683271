import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CANCELLATION_APPLIED': 'Cancellation applied',
        'REMARK_RETIRED': 'Remark retired',
        'RETIRED_CANCELLATION': 'Retired cancellation',
        'DONATION': 'Donation',
        'STATE_ENTERPRISE': 'State enterprise',
        'NO_RETAIL_USE': 'No retail use',
        'BEYOND_EEC_EXPORT': 'Beyond eec export',
        'СREMOTE_SALE': 'Сremote sale',
        'EEC_EXPORT': 'Eec export',
        'СRETURN': 'Сreturn',
        'DAMAGE_LOSS': 'Damage loss',
        'DESTRUCTION': 'Destruction',
        'CONFISCATION': 'Confiscation',
        'LIQUIDATION': 'Liquidation',
      },
    }
  }
}





