import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Object send model |||| Object send models',
      fields: {
      },
    }
  }
}