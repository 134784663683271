import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Кастомная загрузка',
      title: 'Кастомная загрузка',
      executeAction: 'Загрузить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
      fields: {
        'cisPackageTypeUNIT': 'Тип упаковки',
        'documentId': 'Номер документа',
        'packs': 'Фильтрация по товарным упаковкам',
        'statusExt': 'Дополнительный статус КИ',
        'tree': 'Способ вывода дерева КИ',
      }
    }
  }
}