import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import locales from './locales';

import company from './resources/company';
import warehouse from './resources/warehouse';
import account from './resources/account';
import productGroup from './resources/productGroup';
import productGroupName from './resources/productGroupName';
import companySettings from './resources/companySettings';

const resources = [
  company,
  warehouse,
  account,
  productGroup,
  productGroupName,
  companySettings,
];

export default {
  name: 'account',
  locales,
  resources,
  icon: SupervisorAccountIcon,
  documentation: {
    swaggerResourcesPath: '/swagger-resources'
  },
};
