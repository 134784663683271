import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CONTROL_SAMPLES': 'CONTROL_SAMPLES',
        'ARCHIVAL_SAMPLES': 'ARCHIVAL_SAMPLES',
        'CONFIRMATION_COMPLIANCE': 'CONFIRMATION_COMPLIANCE',
      },
    }
  }
}