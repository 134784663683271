import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Переформатировать',
      title: 'Переформатирование',
      executeAction: 'Переформатировать',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос на переформатирование успешно отправлен',
      fields: {
        sscc: 'SSCC',
        mrkIds: 'Остатки',
        participantId: 'id участника',
        aggrType: 'Тип агрегации'
      }
    }
  }
}