import React, { Fragment } from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    List,
    SimpleForm,
    TextField,
    BooleanField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    // BulkDeleteButton,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import ResourceIcon from '@material-ui/icons/Category';

import LinkToList from './LinkToList';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import company from '../company';
import SetAsDefaultButton from './SetAsDefaultButton';
import exporter from '../../../../utils/exporter';
import rest from '../../../../network/rest';
import productGroupName from '../productGroupName';


const listStyles = {
};

const ResourceBulkActionButtons = props => (
    <Fragment>
        {/* <BulkDeleteButton {...props} /> */}
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
    </Filter>
);


const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        bulkActionButtons={<ResourceBulkActionButtons />}
        exporter={exporter}
    >
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'} />
            <BooleanField source={'default'} />
            <company.Field source={'company.id'} />
            <productGroupName.Field source={'uniqName'} />
            <SetAsDefaultButton />
            {/* <EditButton /> */}
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.uniqName
        }&quot;
    </span>
));

const itemToString = (x = null) => {
    if (x === null) {
        return '-'
    };
    return `${x.uniqName} (${x.company.name})`;
}

const validateRequired = required();
const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <company.Input source={'company.id'} validate={validateRequired}/>
            <productGroupName.Input source={'uniqName'} validate={validateRequired}/>
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm>
            <TextField source={'id'} />
            <company.Field source={'company.id'} />
            <productGroupName.Field source={'uniqName'} />
        </SimpleForm>
    </Edit>
);

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <TextField source={'uniqName'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => { console.log(props); return (
    <ReferenceArrayField
        reference={resourceConfig.name}
        perPage={200}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'uniqName'} />
        </SingleFieldList>
    </ReferenceArrayField>
);}

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      perPage={200}
      {...props}
    >
      <SelectInput optionText={itemToString} optionValue={'uniqName'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      label={`resources.${resourceConfig.name}.arrayInputLabel`}
      perPage={200}
      {...props}
    >
      <SelectArrayInput optionText={itemToString} />
    </ReferenceArrayInput>
);



export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,
    
    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        // edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name, 
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        getMany: async(params) => {
            if (params && params.ids) {
                // TODO: костыль для инпута
                const data = await rest.getList(resourceConfig.name, { pagination: { perPage: 200, page: 1 }});
                const resultItems = data.data.filter(x => params.ids.includes(x.uniqName))
                resultItems.forEach(x => x.id = x.uniqName);

                return {
                    data: resultItems,
                    total: resultItems.length,
                };
            }

            return rest.getMany(resourceConfig.name, params);
        },
        schema: {
        },
        filters: {
        },
    }
}
