import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UNIT: 'Unit',
        LEVEL1: 'Level 1',
        LEVEL2: 'Level 2',
        LEVEL3: 'Level 3',
        LEVEL4: 'Level 4',
        LEVEL5: 'Level 5',
        BUNDLE: 'Bundle',
        SET: 'Set',
      },
    },
  },
};
