import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        T1: 'T1',
        T2: 'T2',
      },
    }
  }
}