import React from 'react';
import { registerLocales } from './i18n';
import { registerResource as registerRestProviderResource } from './network/rest';
import ServiceApiDocumentationPage from './components/ServiceApiDocumentationPage';

class AppCore {
  resources = [];
  services = [];
  menuItems = [];
  routes = [];
  
  params = {
    warehouse: null,
    productGroup: null,
  }

  constructor() {
    this.readParams();
  }
  
  readParams = () => {
    this.params.warehouse = localStorage.getItem('warehouse') || this.params.warehouse || null;
    this.params.productGroup = localStorage.getItem('productGroup') || this.params.productGroup || null;
  }

  updateParams = (params = {}) => {
    if ('warehouse' in params) {
      this.params.warehouse = params.warehouse;
      if (params.warehouse === null) {
        localStorage.removeItem('warehouse');
      } else {
        localStorage.setItem('warehouse', params.warehouse);
      }
    }
    if ('productGroup' in params) {
      this.params.productGroup = params.productGroup;
      if (params.productGroup === null) {
        localStorage.removeItem('productGroup');
      } else {
        localStorage.setItem('productGroup', params.productGroup);
      }
    }
  }

  cleanParams = () => {
    this.params.warehouse = null;
    this.params.productGroup = null;
    localStorage.removeItem('warehouse');
    localStorage.removeItem('productGroup');
  }

  registerMenuItem = (item) => {
    this.menuItems.push(item);
    // #TODO: add sort order
  }

  registerLocales = (locales) => registerLocales(locales)

  registerRestProviderResource = (data) => registerRestProviderResource(data)

  registerResource = (res, service = null) => {
    this.resources.push(res);

    if (res.locales) {
      this.registerLocales(res.locales);
    }
    if (res.restProviderResolve) {
      this.registerRestProviderResource(res.restProviderResolve);
    }
    if (res.menuConfig) {
      const serviceName = service === null ? null : service.name;
      this.registerMenuItem({
        serviceName,
        ...res.menuConfig,
      });
    }
  }

  registerResources = (resources, service = null) => {
    resources.forEach((x) => this.registerResource(x, service));
  }

  registerCustomMenuItem = (item, service = null) => {
    const serviceName = service === null ? null : service.name;
    this.registerMenuItem({
      serviceName,
      isCustom: true,
      ...item,
    });
  }

  registerCustomMenuItems = (items, service = null) => {
    items.forEach((x) => this.registerCustomMenuItem(x, service));
  }

  registerService = (service) => {
    if (service.documentation && service.documentation.swaggerResourcesPath) {
      const apiDocUrl = `/${service.name}/api-doc`;
      service.apiDocUrl = apiDocUrl;
      this.routes.push({
        exact: true,
        path: apiDocUrl,
        render: (props) => (
          <ServiceApiDocumentationPage
            service={service}
            {...props}
          />
        ),
      });
    }
    if (service.locales) {
      this.registerLocales(service.locales);
    }
    if (service.resources) {
      this.registerResources(service.resources, service);
    }
    if (service.customMenuItems) {
      this.registerCustomMenuItems(service.customMenuItems, service);
    }

    this.services.push(service);
  }
}

export default new AppCore();
