import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Import third country H',
      importThirdCountryP: 'P records',
      sendDoc: {
        action: 'Send doc',
      },
      checkDocs: {
        action: 'Check docs',
      },
      enums: {
        docType: {
          DECLARATION: 'DECLARATION',
          STATEMENT: 'STATEMENT',
        },
        status: {
          CHECKED_NOT_OK: 'CHECKED_NOT_OK',
          CHECKED_OK: 'CHECKED_OK',
        },
      },
      fields: {
        id: 'Id',
        dateSend: 'Send date',
        docType: 'Document type',
        regDate: 'Registration date',
        decisionDate: 'Decision date',
        docDateCertificate: 'Doument certiifcate date',
        docDatePaper: 'Paper document date',
        status: 'Status',
      },
    },
  },
};
