import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Журнал запросов |||| Журналы запросов',
      fields: {
        body: 'Тело',
        companyUniqName: 'Компания',
        creatorUsername: 'Имя создателя',
        errorMessage: 'Сообщение об ошибке',
        httpClientMess: 'http',
        jsonBody: 'Тело json',
        jsonResult: 'Результат json',
        method: 'Метод',
        sendDate: 'Дата отправки',
        shortErrorMessage: 'Ошибка коротко',
        token: 'Токен',
        url: 'URL',
      },
    }
  }
}