import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'REQUEST_ERROR': 'REQUEST_ERROR',
        'REQUESTED': 'REQUESTED',
        'IN_PROCESS': 'IN_PROCESS',
        'READY': 'READY',
        'CLOSED': 'CLOSED',
      },
    }
  }
}
