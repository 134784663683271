import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Агрегировать',
      title: 'Агрегация',
      executeAction: 'Агрегировать',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос на агрегацию успешно отправлен',
      fields: {
        sscc: 'SSCC',
        mrkIds: 'Остатки',
        participantId: 'Id участника'
      }
    }
  }
}