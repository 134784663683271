import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Company settings |||| Settings',
      fields: {
        server: 'FTP server',
        port: 'FTP port',
        username: 'FTP username',
        password: 'FTP password',
        reportPath: 'FTP report path',
        errorPath: 'FTP error path',
        eanInfoPath: 'FTP ean info path',
        defaultInn: 'INN',
        defaultContactPerson: 'Contact person',
      },
      getOms: {
        action: 'Get oms connection',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
    },
  },
};
