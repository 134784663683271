import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Обновить статусы',
      title: 'Обновить все статусы',
      executeAction: 'Обновить',
      errorMessage: 'Ошибка',
      successMessage: 'Все статусы заказов успешно обновлены',
    }
  }
}