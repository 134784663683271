import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'LP_INTRODUCE_GOODS': 'LP_INTRODUCE_GOODS - Ввод в оборот. Производство РФ json',
        'LP_INTRODUCE_GOODS_CSV': 'LP_INTRODUCE_GOODS_CSV - Ввод в оборот. Производство РФ cdv',
        'LP_INTRODUCE_GOODS_XML': 'LP_INTRODUCE_GOODS_XML - Ввод в оборот. Производство РФ xml',
        'LP_GOODS_IMPORT': 'LP_GOODS_IMPORT - Ввод в оборот. Производство вне ЕАЭС. json',
        'LP_GOODS_IMPORT_CSV': 'LP_GOODS_IMPORT_CSV - Ввод в оборот. Производство вне ЕАЭС. csv',
        'LP_GOODS_IMPORT_XML': 'LP_GOODS_IMPORT_XML - Ввод в оборот. Производство вне ЕАЭС. xml',
        'LK_CONTRACT_COMMISSIONING': 'LK_CONTRACT_COMMISSIONING - Ввод в оборот товара. Контрактное производство РФ. json',
        'LK_CONTRACT_COMMISSIONING_CSV': 'LK_CONTRACT_COMMISSIONING_CSV - Ввод в оборот товара. Контрактное производство РФ. csv',
        'LK_CONTRACT_COMMISSIONING_XML': 'LK_CONTRACT_COMMISSIONING_XML - Ввод в оборот товара. Контрактное производство РФ. xml',
        'LK_INDI_COMMISSIONING': 'LK_INDI_COMMISSIONING - Ввод в оборот товара. Полученных от физических лиц. json',
        'LK_INDI_COMMISSIONING_CSV': 'LK_INDI_COMMISSIONING_CSV - Ввод в оборот товара. Полученных от физических лиц. csv',
        'LK_INDI_COMMISSIONING_XML': 'LK_INDI_COMMISSIONING_XML - Ввод в оборот товара. Полученных от физических лиц. xml',
        'LP_INTRODUCE_OST': 'LP_INTRODUCE_OST - Ввод в оборот. Маркировка остатков json',
        'LP_INTRODUCE_OST_CSV': 'LP_INTRODUCE_OST_CSV - Ввод в оборот. Маркировка остатков csv',
        'LP_INTRODUCE_OST_XML': 'LP_INTRODUCE_OST_XML - Ввод в оборот. Маркировка остатков xml',
        'CROSSBORDER': 'CROSSBORDER - Ввод в оборот. Трансграничная торговля. json',
        'CROSSBORDER_CSV': 'CROSSBORDER_CSV - Ввод в оборот. Трансграничная торговля. csv',
        'CROSSBORDER_XML': 'CROSSBORDER_XML - Ввод в оборот. Трансграничная торговля. xml',
        'LP_SHIP_GOODS': 'LP_SHIP_GOODS - Отгрузка json',
        'LP_SHIP_GOODS_CSV': 'LP_SHIP_GOODS_CSV - Отгрузка csv',
        'LP_SHIP_GOODS_XML': 'LP_SHIP_GOODS_XML - Отгрузка xml',
        'LP_CANCEL_SHIPMENT': 'LP_CANCEL_SHIPMENT - Отмена отгрузки json',
        'LP_CANCEL_SHIPMENT_CSV': 'LP_CANCEL_SHIPMENT_CSV - Отмена отгрузки csv',
        'LP_CANCEL_SHIPMENT_XML': 'LP_CANCEL_SHIPMENT_XML - Отмена отгрузки xml',
        'LP_ACCEPT_GOODS': 'LP_ACCEPT_GOODS - Приемка json',
        'LP_ACCEPT_GOODS_XML': 'LP_ACCEPT_GOODS_XML - Приемка xml',
        'LK_REMARK': 'LK_REMARK - Перемаркировка json',
        'LK_REMARK_CSV': 'LK_REMARK_CSV - Перемаркировка csv',
        'LK_REMARK_XML': 'LK_REMARK_XML - Перемаркировка xml',
        'LK_KM_CANCELLATION': 'LK_KM_CANCELLATION - Списание ненанесенных КМ json',
        'LK_KM_CANCELLATION_CSV': 'LK_KM_CANCELLATION_CSV - Списание ненанесенных КМ csv',
        'LK_KM_CANCELLATION_XML': 'LK_KM_CANCELLATION_XML - Списание ненанесенных КМ xml',
        'LK_APPLIED_KM_CANCELLATION': 'LK_APPLIED_KM_CANCELLATION - Списание нанесенных КМ json',
        'LK_APPLIED_KM_CANCELLATION_CSV': 'LK_APPLIED_KM_CANCELLATION_CSV - Списание нанесенных КМ csv',
        'LK_APPLIED_KM_CANCELLATION_XML': 'LK_APPLIED_KM_CANCELLATION_XML - Списание нанесенных КМ xml',
        'AGGREGATION_DOCUMENT': 'AGGREGATION_DOCUMENT - Агрегация json',
        'AGGREGATION_DOCUMENT_CSV': 'AGGREGATION_DOCUMENT_CSV - Агрегация csv',
        'AGGREGATION_DOCUMENT_XML': 'AGGREGATION_DOCUMENT_XML - Агрегация xml',
        'DISAGGREGATION_DOCUMENT': 'DISAGGREGATION_DOCUMENT - Деагрегация json',
        'DISAGGREGATION_DOCUMENT_CSV': 'DISAGGREGATION_DOCUMENT_CSV - Деагрегация csv',
        'DISAGGREGATION_DOCUMENT_XML': 'DISAGGREGATION_DOCUMENT_XML - Деагрегация xml',
        'REAGGREGATION_DOCUMENT': 'REAGGREGATION_DOCUMENT - Переагрегация json',
        'REAGGREGATION_DOCUMENT_CSV': 'REAGGREGATION_DOCUMENT_CSV - Переагрегация csv',
        'REAGGREGATION_DOCUMENT_XML': 'REAGGREGATION_DOCUMENT_XML - Переагрегация xml',
        'OST_DESCRIPTION': 'OST_DESCRIPTION - Описание остатков товара json',
        'OST_DESCRIPTION_CSV': 'OST_DESCRIPTION_CSV - Описание остатков товара csv',
        'OST_DESCRIPTION_XML': 'OST_DESCRIPTION_XML - Описание остатков товара xml',

        'UNIVERSAL_TRANSFER_DOCUMENT' : 'UNIVERSAL_TRANSFER_DOCUMENT',
        'INCLUDE_DOCUMENT' : 'INCLUDE_DOCUMENT',
        'RECEIPT' : 'RECEIPT',
        'LP_ADD_GOODS_CSV' : 'LP_ADD_GOODS_CSV',
        'LP_ADD_GOODS_XML' : 'LP_ADD_GOODS_XML',
        'LK_REGISTRATION' : 'LK_REGISTRATION',
        'LK_REGISTRATION_XML' : 'LK_REGISTRATION_XML',
        'LK_RESUME_ACCESS' : 'LK_RESUME_ACCESS',
        'LK_RESUME_ACCESS_XML' : 'LK_RESUME_ACCESS_XML',
        'LK_CHANGE_EMAIL_XML' : 'LK_CHANGE_EMAIL_XML',
        'LK_CHANGE_EMAIL' : 'LK_CHANGE_EMAIL',
        'LK_LOAN_RECEIPT' : 'LK_LOAN_RECEIPT',
        'LK_LOAN_RECEIPT_CSV' : 'LK_LOAN_RECEIPT_CSV',
        'LK_RECEIPT_XML' : 'LK_RECEIPT_XML',
        'LK_BLOCKING' : 'LK_BLOCKING',
        'LK_BLOCKING_XML' : 'LK_BLOCKING_XML',
        'LK_RECEIPT_CSV' : 'LK_RECEIPT_CSV',
        'INTERNAL_RECEIPT' : 'INTERNAL_RECEIPT',
        'LK_RECEIPT' : 'LK_RECEIPT',
        'LK_LOAN_RECEIPT_XML' : 'LK_LOAN_RECEIPT_XML',
        'LP_SHIP_RECEIPT' : 'LP_SHIP_RECEIPT',
        'LP_SHIP_RECEIPT_CSV' : 'LP_SHIP_RECEIPT_CSV',
        'ORDER' : 'ORDER',
        'LP_SHIP_RECEIPT_XML' : 'LP_SHIP_RECEIPT_XML',
        'LP_FTS_INTRODUCE': 'LP_FTS_INTRODUCE',
        'LP_FTS_INTRODUCE_CSV': 'LP_FTS_INTRODUCE_CSV',
        'LP_FTS_INTRODUCE_XML': 'LP_FTS_INTRODUCE_XML',
        'NK_REQUEST': 'NK_REQUEST',
      },
    }
  }
}





