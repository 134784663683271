import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Вывод из оборота',
      outP: 'Позиции',
      sendDoc: {
        action: 'Отправить',
      },
      checkDocs: {
        action: 'Проверить статус',
      },
      enums: {
        docType: {
          DECLARATION: 'DECLARATION',
        },
        status: {
          CHECKED_NOT_OK: 'CHECKED_NOT_OK',
          CHECKED_OK: 'CHECKED_OK',
        },
      },
      fields: {
        fileId: 'GUID',
        id: 'Id',
        dateSend: 'Дата отправки',
        docType: 'Тип документа',
        regDate: 'Дата регистрации',
        decisionDate: 'Дата принятия решения',
        docDateCertificate: 'Дата сертификата',
        docDatePaper: 'Дата бумажного документа',
        status: 'Статус',
        reason: 'Причина вывода товаров из оборота',
        participantName: 'Имя участника',
        docName: 'Имя документа',
        docNumber: 'Номер документа',
        participantIdentificationCode: 'Код участника',
        docDate: 'Дата первичного документа в формате dd.mm.yyyy',
        status: 'Статус ИС МПТ',
      },
    },
  },
};
