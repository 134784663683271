import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Настройка компании |||| Настройки',
      fields: {
        sysId: 'Id системы',
        tokenInfo: 'Токен инфо',
        uniqCertParam: 'Параметр сертификата',
        uniqName: 'Компания в системе',
        omsId: 'ОМС Id',
        omsToken: 'ОМС Токен',
        server: 'FTP сервер',
        port: 'FTP порт',
        username: 'FTP пользователь',
        password: 'FTP пароль',
        reportPath: 'FTP директория для отчетов',
        errorPath: 'FTP директория для ошибок',
        eanInfoPath: 'FTP директория для EAN инфо',
        defaultInn: 'ИНН',
        defaultContactPerson: 'Контактное лицо',
        irreducibleStockCount: 'Неснижаемый запас (кол-во)',
        glnForSSCC: 'GLN для SSCC',
        actualVirtualSSCC: ' Cчетчик для виртуального SSCC',
      },
      getOms: {
        action: 'Получение OMS Connection',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
    },
  },
};
