import React, { Component } from 'react';
import { Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import getAuthHeaders from '../../network/getAuthHeaders';
import request from '../../network/request';
import getServiceUrl from '../../network/getServiceUrl';


const styles = (p) => ({
  head: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 12,
      paddingBottom: 12,
  },
  headSelect: {
    width: '100%',
    maxWidth: 300
  },
  swaggerWrap: {
    '& .swagger-ui': {
      '& .info .title': {
        color: p.palette.text.primary,
      }, 
      '& .opblock-tag, & .opblock-tag small': {
        color: p.palette.text.primary,
      },
      '& .info li, & .info p, & .info table': {
        color: p.palette.text.primary,
      },
      '& .opblock .opblock-summary-operation-id, & .opblock .opblock-summary-path, & .opblock .opblock-summary-description': {
        color: p.palette.text.primary,
      },
      '& section.models h4, & .model, & .model-title': {
        color: p.palette.text.secondary,
      },
    }
  }
});

class ServiceApiDocumentationPage extends Component {
  state = {
    resources: [],
    resourceName: null,
  }

  componentDidMount() {
    this.loadResources();
  }

  loadResources = async () => {
    const {
      service,
    } = this.props;

    const response = await request({
      apiService: service.name,
      path: service.documentation.swaggerResourcesPath,
    });

    if (response.ok) {
      this.setState({
        resources: response.data,
        resourceName: response.data[0].name,
      })
    }
  }

  getSwaggerUrl = () => {
    const {
      service
    } = this.props;
    const {
      resources,
      resourceName,
    } = this.state;

    const currentResource = resources.find(x => x.name === resourceName);
    if (currentResource === undefined) {
      return null;
    }
    return getServiceUrl(service.name, { path: currentResource.url })
  }

  handleChange = (event) => {
    this.setState({
      resourceName: event.target.value
    });
  }

  requestInterceptor = async (data) => {
    const authHeaders = await getAuthHeaders();

    data.headers = {
      ...data.headers,
      ...authHeaders,
    };

    return data;
  }

  render() {
    const {
      classes
    } = this.props;
    const {
      resources,
      resourceName,
    } = this.state;
    if (resources.length === 0) {
      return null;
    }

    const swaggerUrl = this.getSwaggerUrl();

    return (
      <div>
        <Title title={'Api documentation'} />
        <div className={classes.head}>
          <FormControl
            className={classes.headSelect}
          >
            <InputLabel id="api-doc-select-spec-label">Spec</InputLabel>
            <Select
              labelId="api-doc-select-spec-label"
              id="api-doc-select-spec"
              value={resourceName}
              onChange={this.handleChange}
            >
              {resources.map(x => (
                <MenuItem value={x.name}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {swaggerUrl !== null && (
          <Card>
            <CardContent className={classes.swaggerWrap}>
              <SwaggerUI
                url={swaggerUrl}
                requestInterceptor={this.requestInterceptor}
              />
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ServiceApiDocumentationPage);