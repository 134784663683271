import { createHashHistory } from 'history';

import createStore from "./createStore";
import authProvider from '../network/authProvider';
import restProvider from '../network/rest';


const history = createHashHistory();

const store = createStore({
  authProvider,
  dataProvider: restProvider,
  history,
});

export default store;
