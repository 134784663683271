import printJS from 'print-js';

export default (blob) => {
  var reader = new FileReader();
  reader.readAsDataURL(blob); 
  reader.onloadend = function() {
      var base64data = reader.result;
      printJS({printable: base64data.replace(/^.*base64,/, ''), type: 'pdf', base64: true})
  }
}
