import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Good |||| Goods',
      createOrder: {
        action: 'Create order',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      load: {
        action: 'Load',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        processedDate: 'Processed date',
      }
    }
  }
}