import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Уведомление |||| Уведомления',
      fields: {
        email: 'Email',
        active: 'Активно',
        ismpDocumentType: 'Тип дока ИСМП',
        ismpDocumentStatus: 'Статус дока ИСМП',
        mails: 'Адреса',
      }
    }
  }
}