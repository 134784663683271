import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Скачать',
      openActionPdf: 'Скачать pdf',
      openActionCsv: 'Скачать csv',
      title: 'Скачать',
      executeAction: 'Скачать',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
    }
  }
}