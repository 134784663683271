import defaultConfig from "./defaultConfig";
import parseEnv from "../utils/parseEnv";


class AppConfig {
  loadedState = null;
  data = defaultConfig;

  loadConfig = async () => {
    this.loadedState = 'loading';
    const response = await fetch(this.data.configUrl);
    if (response.ok) {
      const text = await response.text();
      const data = parseEnv(text);
      if (data.API_AUTH_BASE_URL) {
        this.data.services.auth.baseUrl = data.API_AUTH_BASE_URL;
      }
      if (data.API_ACCOUNT_BASE_URL) {
        this.data.services.account.baseUrl = data.API_ACCOUNT_BASE_URL;
      }
      if (data.API_SHOES_BASE_URL) {
        this.data.services.shoes.baseUrl = data.API_SHOES_BASE_URL;
      }
      if (data.API_MARKING_BASE_URL) {
        this.data.services.marking.baseUrl = data.API_MARKING_BASE_URL;
      } else if (data.API_SHOES_BASE_URL) {
        this.data.services.marking.baseUrl = data.API_SHOES_BASE_URL;
      }
      if (data.API_KEYCLOAK_BASE_URL) {
        this.data.services.keycloak.baseUrl = data.API_KEYCLOAK_BASE_URL;
      } else if (data.API_AUTH_BASE_URL) {
        this.data.services.keycloak.baseUrl = data.API_AUTH_BASE_URL.replace('//api-', '//account-').replace('/uaa', '')
      }
      if (data.API_KEYCLOAK_REALM) {
        this.data.services.keycloak.realm = data.API_KEYCLOAK_REALM;
      }
      if (data.API_KEYCLOAK_CLIENT_ID) {
        this.data.services.keycloak.clientId = data.API_KEYCLOAK_CLIENT_ID;
      }
      if (data.API_KEYCLOAK_CLIENT_SECRET) {
        this.data.services.keycloak.clientSecret = data.API_KEYCLOAK_CLIENT_SECRET;
      }
      if (data.API_DATE_FORMAT) {
        this.data.apiDateTimeFormat = data.API_DATE_FORMAT;
      }
      this.loadedState = 'loaded';
    } else {
      this.loadedState = 'error';
    }
  }
}

export default new AppConfig();
