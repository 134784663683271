import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Balance journal |||| Balance journal',
      mrkInput: {
        action: 'Input',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      soaSuiteSend: {
        action: 'SOA Suite Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      bulkUpdate: {
        action: 'Update',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      mrkDoc: {
        action: 'Doc',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      load: {
        action: 'Load',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      filters: {
        emissionDateFrom: 'Emission date from',
        emissionDateTo: 'Emission date to',
      },
      updateKM: {
        action: 'Refresh',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      updateAllKM: {
        action: 'Refresh all',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        id: 'ID',
        cis: 'cis',
        gtin: 'gtin',
        sgtin: 'sgtin',
        tnvedGroup: 'Tnved group',
        ownerName: 'Owner name',
        producerInn: 'Producer inn',
        producerName: 'Producer name',
        ownerBin: 'Owner bin',
        producerBin: 'Producer bin',
        cisPackageType: 'Cis package type',
        productName: 'Product name',
        mrkSystemStatus: 'Mrk system status',
        status: 'Status',
        statys: 'Status',
        currentchldtype: 'Child type',
        parentId: 'Parent',
        prnttype: 'Parent type',
        status: 'Status',
        value: 'SSCC',
        emmissionDate: 'Emission date',
        producedDate: 'Produced date',
        dateInvoice: 'Date invoice',
        invoiceAtkDate: 'Invoice Atk Date',
        dateGtd: 'Date GTD',
        atk: 'ATK',
        contactPerson: 'Contact Person',
      },
    },
  },
};
