export default {
  enums: [
    { id: 'EMITTED' },
    { id: 'APPLIED' },
    { id: 'INTRODUCED' },
    { id: 'RECYCLED' },
    { id: 'RETIRED' },
    { id: 'RESERVED_NOT_USED' },
    { id: 'INTRODUCED_RETURNED' },
    { id: 'DISAGGREGATED' },
    { id: 'WAIT_SHIPMENT' },
    { id: 'EXPORTED' },
    { id: 'LOAN_RETIRED' },
    { id: 'REMARK_RETIRED' },
    { id: 'READY_FOR_EXTERNAL_PROCESSING' },
    { id: 'OK' },
  ]
};