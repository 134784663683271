import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Сustom file |||| Сustom files',
      fields: {
        inputhId: 'Input H Id',
        remainder: 'Reminder',
      }
    }
  }
}