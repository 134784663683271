import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN: 'Входящий',
        OUT: 'Исходящий',
        '1': 'Входящий',
        '2': 'Исходящий',
      },
    }
  }
}