import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Получить KM',
      title: 'Получить KM',
      executeAction: 'Получить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
    }
  }
}