import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Деагрегировать',
      title: 'Деагрегация',
      executeAction: 'Деагрегировать',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос на Деагрегацию успешно отправлен',
      fields: {
        mrkIds: 'Остатки',
        participantId: 'id участника'
      }
    }
  }
}