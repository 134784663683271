import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
      'ACTIVE': 'ACTIVE',
      'CLOSED': 'CLOSED',
      'PENDING': 'PENDING',
      'REJECTED': 'REJECTED',
      'EXHAUSTED': 'EXHAUSTED',
      'CREATE': 'CREATE',
      'DECLINE': 'DECLINE',
      'READY': 'READY',
      },
    }
  }
}