import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN_PROGRESS: 'In progress',
        CHECKED_OK: 'Checked ok',
        CHECKED_NOT_OK: 'Checked not ok',
        PROCESSING_ERROR: 'Processing error',
        UNDEFINED: 'Undefined',
        CANCELLED: 'Cancelled',
        ACCEPTED: 'Accepted',
        WAIT_ACCEPTANCE: 'Wait acceptance',
        WAIT_PARTICIPANT_REGISTRATION: 'Wait participant registration',
        PENDING: 'Pending',
        ACTIVE: 'Active',
        EXHAUSTED: 'Exhausted',
        REJECTED: 'Rejected',
        CLOSED: 'Closed',
        ERROR: 'Error',
        NK_REQUEST_ERROR: 'NK_REQUEST_ERROR',
      },
    },
  },
};
