import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import { translate } from 'react-admin';
import request from '../../network/request';
import appCore from '../../appCore';

class WarehouseSelect extends Component {
  constructor(props) {
    super(props);

    const value = appCore.params.warehouse || null;

    this.state = {
      loading: true,
      allItems: [],
      anchorEl: null,
      value,
    }
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    let response = null;
    try {
      response = await request({
        apiService: 'account',
        path: '/current/warehouse',
        method: 'GET',
      })
    } catch (e) {
      console.error(e);
    }
    let allItems = [];
    if (response !== null && response.ok) {
      allItems = response.data.map(x => ({ uniqName: x, title: x }));
    }  
    this.setState((state) => {
      let value = state.value;
      const existItem = allItems.find(x => x.uniqName === value);
      if (existItem === undefined) {
        value = null;
      }
      return {
        value,
        loading: false,
        allItems,
      };
    });
  }
  
  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  
  getLabel = () => {
    const {
      translate,
    } = this.props;
    if (this.state.value === null) {
      return translate('WarehouseSelect.placeholder');
    }
    const item = this.state.allItems.find(x => x.uniqName === this.state.value);
    if (item === undefined) {
      return this.state.value;
    }

    return `${item.title}`;
  }

  onSelect = (event) => {
    const value = event.currentTarget.dataset.uniqName || null;
    if (value === this.state.value) {
      this.setState({
        anchorEl: null,
      });
      return;
    }

    appCore.updateParams({ warehouse: value });
    this.setState({
      value,
      anchorEl: null,
    });
    window.location.reload();
  }

  render() {
    const {
      translate
    } = this.props;
    const {
      loading,
      allItems,
    } = this.state;

    if (allItems.length === 0 && loading) {
      return '...';
    }

    return (
      <div>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
        >
          {this.getLabel()}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {/* <MenuItem
            onClick={this.onSelect}
          >
            {translate('WarehouseSelect.default')}
          </MenuItem> */}
          {allItems.length === 0 && (
            <MenuItem
              disabled
            >
              {translate('WarehouseSelect.noWarehouses')}
            </MenuItem>
          )}
          {allItems.map((item) => (
            <MenuItem
              onClick={this.onSelect}
              data-uniq-name={item.uniqName}
              key={item.uniqName}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default translate(WarehouseSelect);
