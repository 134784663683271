import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        username: 'Имя',
        password: 'Пароль',
        authorities: 'Полномочия',
      }
    }
  }
}