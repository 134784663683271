import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Print Journal',
      printJournalElement: 'Elements',
      pdfAction: {
        action: 'Create PDF',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      csvAction: {
        action: 'Create CSV',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      loadAction: {
        action: 'Load',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        menge: 'Quantity',
      }
    }
  }
}