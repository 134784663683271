// in src/App.js
import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';

import LoginPage from '../LoginPage';
import Dashboard from '../Dashboard';
import Layout from '../../Layout';
import Menu from '../../components/Menu';
import './App.css';

class App extends Component {
  render() {
    const {
      resources,
      dataProvider,
      authProvider,
      history,
      i18nProvider,
      routes,
    } = this.props;
    return (
      <Admin
        title={'Marking'}
        dataProvider={dataProvider}
        customRoutes={routes}
        authProvider={authProvider}
        dashboard={Dashboard}
        history={history}
        loginPage={LoginPage}
        layout={Layout}
        menu={Menu}
        i18nProvider={i18nProvider}
      >
        {resources.filter(x => x.AppModule).map(({ name, AppModule }) => (
          <AppModule
            key={name}
          />
        ))}

        {resources.filter(x => x.resourceConfig).map(res => (
          <Resource
            key={res.name}
            hasDelete={false}
            {...res.resourceConfig}
          />
        ))}
      </Admin>
    )
  }
}

export default App;
