export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            main: '#F0F0F0',
            light: '#FFFFFF',
            dark: '#C0C0C0',
            contrastText: '#101010',
        },
        secondary: {
            main: '#3F51B5',
            light: '#C5CAE9',
            dark: '#303F9F',
            contrastText: '#FFFFFF',
        },
    },
    overrides: {
        MuiButton: {
            'contained': {
                backgroundColor: '#999',
            },
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#03A9F4',
            light: '#B3E5FC',
            dark: '#0288D1',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#03A9F4',
            light: '#B3E5FC',
            dark: '#0288D1',
            contrastText: '#FFFFFF',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        RaFormInput: {
            input: {
                width: 500,
                maxWidth: '100%',
            }
        }
    },
};
