import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Disaggregate',
      title: 'MRK disaggregation',
      executeAction: 'Disaggregate',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        mrkIds: 'Items',
        participantId: 'Participant'
      }
    }
  }
}