import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DONATION: 'DONATION',
        STATE_ENTERPRISE: 'STATE_ENTERPRISE',
        NO_RETAIL_USE: 'NO_RETAIL_USE',
      },
    }
  }
}