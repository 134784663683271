import React, { Fragment } from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    BulkDeleteButton,
    CreateButton,
    ExportButton,
    BooleanInput,
    BooleanField,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import LinkToList from './LinkToList';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import exporter from '../../../../utils/exporter';
import ismpDocumentType from '../ismpDocumentType';
import ismpDocumentStatus from '../ismpDocumentStatus';

const ResourceIcon = BookmarkIcon;

const listStyles = {
};

const ResourceBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
    </Filter>
);


const ResourceListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    translate
}) => (
        <Toolbar>
            {bulkActions && React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </Toolbar>
    );

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        actions={<ResourceListActions />}
        filters={<ResourceListFilter />}
        bulkActionButtons={<ResourceBulkActionButtons />}
        exporter={exporter}
    >
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'} />

            <BooleanField source={'active'} />
            <ismpDocumentType.Field source={'ismpDocumentType'} />
            <ismpDocumentStatus.Field source={'ismpDocumentStatus'} />

            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.id
        }&quot;
    </span>
));


const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <BooleanInput source={'active'} />
            <ismpDocumentType.Input source="ismpDocumentType" fullWidth style={{ maxWidth: 600 }} />
            <ismpDocumentStatus.Input source="ismpDocumentStatus" fullWidth style={{ maxWidth: 600 }} />
            <ArrayInput source="mails">
                <SimpleFormIterator>
                    <TextInput source="email" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm>
            <BooleanInput source={'active'} />
            <ismpDocumentType.Input source="ismpDocumentType" fullWidth style={{ maxWidth: 600 }} />
            <ismpDocumentStatus.Input source="ismpDocumentStatus" fullWidth style={{ maxWidth: 600 }} />
            <ArrayInput source="mails">
                <SimpleFormIterator>
                    <TextInput source="email" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <TextField source={'id'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput
        reference={resourceConfig.name}
        {...props}
    >
        <SelectInput optionText={'id'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput
        reference={resourceConfig.name}
        {...props}
    >
        <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
);



export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name,
        icon: ResourceIcon,
    },
    locales: resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
            dateFrom: {
                type: 'dateTime',
            },
            dateTo: {
                type: 'dateTime',
            },
        },
        filters: {
            dateFrom: {
                type: 'dateTime',
            },
            dateTo: {
                type: 'dateTime',
            },
        },
        mapping: {
            inputItem: (item) => {
                const emails = item.mails;
                item.mails = []
                if (Array.isArray(emails)) {
                    item.mails = emails.map(x => ({ email: x }));
                }
                return item;
            },
            outputItem: (item) => {
                if (Array.isArray(item.mails)) {
                    item.mails = item.mails.map(x => x.email);
                }
                return item;
            }
        }
    }
}
