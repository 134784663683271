import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        EMITTED: 'Эмитирован',
        APPLIED: 'Нанесён',
        INTRODUCED: 'Введён в оборот',
        WRITTEN_OFF: 'Выведен из оборота, списан',
        WITHDRAWN: 'Выведен из оборота, продан',
        UNDEFINED: 'Неопределен',
        RETIRED: 'Выбыл',
        DISAGGREGATION: 'Расформирован (только для упаковок)',
        WAIT_FOR_CONTINUATION: 'Ожидаем продолжения процессинга документа. Для ФТС',
      },
    }
  }
}
