import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  NumberInput,
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  DateField,
  BooleanInput,
  BooleanField,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
// import BookmarkIcon from '@material-ui/icons/Inbox';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';

// import MarkingStatus from '../markingStatus';
import MarkingType from '../markingType';
import CodeType from '../codeType';
import EmissionType from '../emissionType';
import SerialNumType from '../serialNumType';
import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import BulkAggregateAction from './BulkAggregateAction';
import BulkDisaggregateAction from './BulkDisaggregateAction';
import BulkReaggregateAction from './BulkReaggregateAction';
import MrkInputButton from './MrkInputButton';
import MrkDocButton from './MrkDocButton';
import treeMrkType from '../treeMrkType';
import mrkStatusSystem from '../mrkStatusSystem';
import markingStatus from '../markingStatus';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import errorModel from '../errorModel';
import emissionType from '../emissionType';
import serialNumType from '../serialNumType';
import docStatusSystem from '../docStatusSystem';
import markingType from '../markingType';
import codeType from '../codeType';
import LoadButton from './LoadButton';
import UpdateKmButton from './UpdateKmButton';
import treeMrkLoadCustom from '../treeMrkLoadCustom';
import exporter from '../../../../utils/exporter';
import UpdateKmAllButton from './UpdateKmAllButton';
import cisPackageType from '../cisPackageType';
import BulkSoaSuiteSendAction from './BulkSoaSuiteSendAction';
import BulkUpdateAction from './BulkUpdateAction';
import mrkPackageType from '../mrkPackageType';
import PostPagination from '../../../../utils/pagination';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x = {}) => `#${x.id}`;

const listStyles = {};

// const PackMButton = (props) => {
//     return (
//         <PackH.LinkToList
//             filter={{
//                 packhIds: props.record.id
//             }}
//         />
//     );
// }

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <markingStatus.Input source={'status'} />
    <TextInput source={'orderNum'} />
    <DateInput
      source={'emissionDateFrom'}
      label={`resources.${resourceConfig.name}.filters.emissionDateFrom`}
    />
    <DateInput
      source={'emissionDateTo'}
      label={`resources.${resourceConfig.name}.filters.emissionDateTo`}
    />
    <CodeType.Input source={'codeType'} />
    <TextInput source={'currentchldtype'} />
    <EmissionType.Input source={'emissionType'} />
    <TextInput source={'gtin'} />
    <TextInput source={'lastDocId'} />
    <MarkingType.Input source={'markingType'} />
    <mrkPackageType.Input source={'сisPackageType'} />
    <mrkStatusSystem.Input source={'mrkSystemStatus'} />
    <NumberInput source={'parentIds'} />
    <TextInput source={'prnttype'} />
    <TextInput source={'productName'} />
    <SerialNumType.Input source={'serialNumType'} />
    <TextInput source={'serialNumber'} />
    <TextInput source={'tnVedCode'} />
    <TextInput source={'value'} />
    <TextInput source={'atk'} />
    <BooleanInput source={'updated'} />
    <BooleanInput source={'owner'} />
    <TextInput source={'invoiceAtk'} />
    <TextInput source={'numdoc'} />
    <TextInput source={'manufacturingItem'} />
    <TextInput source={'itemCode'} />
    <TextInput source={'utd'} />
    <TextInput source={'mut'} />
    <TextInput source={'trackNumber'} />
    <TextInput source={'invoice'} />
    <TextInput source={'numGoods'} />
    <TextInput source={'uit'} />
    <TextInput source={'uituCode'} />
    <TextInput source={'descriptionNumdoc'} />
    <DateInput
      source={'dateInvoiceByDate'}
      label={`resources.${resourceConfig.name}.fields.dateInvoice`}
    />
    <DateInput
      source={'invoiceAtkDateByDate'}
      label={`resources.${resourceConfig.name}.fields.invoiceAtkDate`}
    />

    <TextInput source={'gtins'} />
    <TextInput source={'numsGoods'} />
    <TextInput source={'invoices'} />
    <TextInput source={'manufacturingItems'} />
    <TextInput source={'itemCodes'} />
    <TextInput source={'ssccs'} />
    <TextInput source={'mrkSystemStatuses'} />
    <TextInput source={'statuses'} />
    <TextInput source={'ATKs'} />
    <TextInput source={'uitCodes'} />
    <TextInput source={'uituCodes'} />
    <TextInput source={'contactPerson'} />
    <TextInput source={'ownerInn'} />
    <TextInput source={'ownerInns'} />
  </Filter>
);

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    {/* <TreeMrkForBrokerButton {...props} /> */}
    <BulkSoaSuiteSendAction {...props} />
    <MrkInputButton {...props} />
    <MrkDocButton {...props} />
    <BulkUpdateAction {...props} />
    <BulkAggregateAction {...props} />
    <BulkDisaggregateAction {...props} />
    <BulkReaggregateAction {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <treeMrkLoadCustom.OpenButton />
    <UpdateKmAllButton />
    <LoadButton />
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    actions={<ResourceListActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'gtin'} />
      <TextField source={'serialNumber'} />
      <TextField source={'value'} />
      <emissionType.Field source={'emissionType'} />
      <DateField source={'statusChangeDate'} showTime />
      <mrkStatusSystem.Field source={'mrkSystemStatus'} />
      <markingStatus.Field source={'status'} />
      <BooleanField source={'updated'} />
      <TextField source={'atk'} />
      <TextField source={'manufacturingItem'} />
      <TextField source={'itemCode'} />
      <TextField source={'contactPerson'} />
      <TextField source={'ownerInn'} />
      <UpdateKmButton />
      <errorModel.LinkToRelatedList type={'TREEMRK'} />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <treeMrkType.Input source={'currentchldtype'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <cisPackageType.Input source={'cisPackageType'} />
      <markingStatus.Input source={'status'} />
      <NumberInput source={'parentId'} />
      <treeMrkType.Input source={'prnttype'} />
      <TextInput source={'username'} />
      <TextInput source={'value'} />
      <NumberInput source={'ordernum'} />

      <TextInput source={'gtin'} />
      <TextInput source={'tnVedCode'} />
      <TextInput source={'tnVed10'} />
      <TextInput source={'productName'} />
      <emissionType.Input source={'emissionType'} />
      <serialNumType.Input source={'serialNumType'} />
      <TextInput source={'serialNumber'} />
      <markingType.Input source={'markingType'} />
      <codeType.Input source={'codeType'} />
      <TextInput source={'ownerInn'} />
      <TextInput source={'lastDocId'} />

      <TextInput source={'uitCode'} />

      <docStatusSystem.Input source={'documentSystemStatus'} />

      <NumberInput source={'errorModelId'} />

      <TextInput source={'cryptoKey'} />
      <TextInput source={'cryptoCode'} />
      <TextInput source={'temp'} />

      <TextInput source={'numGoods'} />
      <TextInput source={'invoice'} />
      <DateInput
        source={'dateInvoice'}
        label={`resources.${resourceConfig.name}.fields.dateInvoice`}
      />

      <TextInput source={'gtd'} />
      <DateInput source={'dateGtd'} label={`resources.${resourceConfig.name}.fields.dateGtd`} />

      <DateInput
        source={'emmissionDate'}
        label={`resources.${resourceConfig.name}.fields.emmissionDate`}
      />
      <DateInput
        source={'producedDate'}
        label={`resources.${resourceConfig.name}.fields.producedDate`}
      />
      <BooleanInput source={'updated'} />

      <TextInput source={'numdoc'} />
      <TextInput source={'manufacturingItem'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'utd'} />
      <TextInput source={'mut'} />
      <TextInput source={'timestampDate'} />
      <TextInput source={'trackNumber'} />
      <TextInput source={'numpos'} />
      <TextInput source={'descriptionNumdoc'} />
      <TextInput source={'counrty'} />
      <TextInput source={'atk'} />
      <TextInput source={'invoiceAtk'} />
      <TextInput source={'invoiceAtkDate'} />
      <TextInput source={'invoiceAtkPos'} />
      <TextInput source={'contactPerson'} />
      <TextInput source={'statusEx'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm>
      <TextField source={'id'} />
      <treeMrkType.Input source={'currentchldtype'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <cisPackageType.Input source={'cisPackageType'} />
      <NumberInput source={'parentId'} />
      <treeMrkType.Input source={'prnttype'} />
      <markingStatus.Input source={'status'} />
      <TextInput source={'username'} />
      <TextInput source={'value'} />
      <NumberInput source={'ordernum'} />

      <TextInput source={'gtin'} />
      <TextInput source={'tnVedCode'} />
      <TextInput source={'tnVed10'} />
      <TextInput source={'productName'} />
      <emissionType.Input source={'emissionType'} />
      <serialNumType.Input source={'serialNumType'} />
      <TextInput source={'serialNumber'} />
      <markingType.Input source={'markingType'} />
      <codeType.Input source={'codeType'} />
      <TextInput source={'ownerInn'} />
      <TextInput source={'lastDocId'} />

      <TextInput source={'uitCode'} />

      <docStatusSystem.Input source={'documentSystemStatus'} />

      <NumberInput source={'errorModelId'} />

      <TextInput source={'cryptoKey'} />
      <TextInput source={'cryptoCode'} />
      <TextInput source={'temp'} />

      <TextInput source={'numGoods'} />
      <TextInput source={'invoice'} />
      <DateInput
        source={'dateInvoice'}
        label={`resources.${resourceConfig.name}.fields.dateInvoice`}
      />

      <TextInput source={'gtd'} />
      <DateInput source={'dateGtd'} label={`resources.${resourceConfig.name}.fields.dateGtd`} />

      <DateInput
        source={'emmissionDate'}
        label={`resources.${resourceConfig.name}.fields.emmissionDate`}
      />
      <DateInput
        source={'producedDate'}
        label={`resources.${resourceConfig.name}.fields.producedDate`}
      />
      <BooleanInput source={'updated'} />

      <TextInput source={'numdoc'} />
      <TextInput source={'manufacturingItem'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'utd'} />
      <TextInput source={'mut'} />
      <TextInput source={'timestampDate'} />
      <TextInput source={'trackNumber'} />
      <TextInput source={'descriptionNumdoc'} />
      <TextInput source={'numpos'} />
      <TextInput source={'counrty'} />
      <TextInput source={'atk'} />
      <TextInput source={'invoiceAtk'} />
      <TextInput source={'invoiceAtkDate'} />
      <TextInput source={'invoiceAtkPos'} />
      <TextInput source={'contactPerson'} />
      <TextInput source={'statusEx'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      emmissionDate: {
        type: 'dateTime',
      },
      emissionDateFrom: {
        type: 'dateTime',
      },
      emissionDateTo: {
        type: 'dateTime',
      },
      producedDate: {
        type: 'dateTime',
      },
      dateInvoice: {
        type: 'dateTime',
      },
      invoiceAtkDate: {
        type: 'dateTime',
      },
      dateGtd: {
        type: 'dateTime',
      },
      statusChangeDate: {
        type: 'dateTime',
      },
      timestampDate: {
        type: 'dateTime',
      },
      dateInvoiceByDate: {
        type: 'date',
      },
      invoiceAtkDateByDate: {
        type: 'date',
      },
    },
  },
};
