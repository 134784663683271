import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CREATE': 'создан',
        'ACTIVE': 'активный',
        'CLOSED': 'закрыт',
        'PENDING': 'ожидание',
        'REJECTED': 'отказ',
        'EXHAUSTED': 'исчерпан',
        'DECLINE': 'отказ',
      },
    }
  }
}