import russianMessages from 'ra-language-russian';

export default {
  ...russianMessages,
  pos: {
    title: 'Сервис Маркировки',
    configuration: 'Настройки',
    language: 'Язык',
    theme: {
      name: 'Тема',
      light: 'Светлая',
      dark: 'Темная',
    },
    dashboard: {
      welcome: {
        title: 'Добро пожаловать',
        subtitle: '',
      },
    },
    login: {
      hint: 'Войдите в свой аккаунт',
    },
  },
  resources: {},
  WarehouseSelect: {
    placeholder: 'Выберите склад',
    default: 'Основной склад',
    noWarehouses: 'Нет доступных складов',
  },
  ProductGroupSelect: {
    label: 'Товарная группа',
    hint: 'Доступные группы:',
    placeholder: 'Выберите группу',
    default: 'Группа по-умолчанию',
    noGroups: 'Нет доступных групп',
  },
  changePassowrdPage: {
    title: 'Смена пароля',
    errorMessage: 'Ошибка',
    successMessage: 'Пароль успешно изменен',
    fields: {
      password: 'Текущий пароль',
      newPassword: 'Новый пароль',
    },
  },
  searchKMPage: {
    title: 'Поиск кодов',
    resultTitle: 'Рузльтаты поиска',
    errorMessage: 'Запрос упал с ошибкой',
    invalidResponseMessage: 'Неверный формат ответа',
    noCodesFoundMessage: 'Коды не найдены',
    validationMessage: {
      uitLengthMin21: 'Необходимо указать не менее 21 символа в поле UIT',
      serialNumberLengthMin5: 'Необходимо указать не менее 5 символов в поле serialNumber',
      gtinLengthMin14: 'Необходимо указать не менее 14 символов в поле GTIN',
      serialNumberRequired: 'Необходимо дополнительно указать значение вполе serialNumber',
    },
    fields: {
      cis: 'Cis Код',
      sgtin: 'Серийный номер',
      gtin: 'GTIN',
    },
  },
  menu: {
    serviceApiDoc: 'API документация',
  },
  productGroups: {
    clothes: {
      name: 'Одежда',
    },
    shoes: {
      name: 'Обувь',
    },
    tobacco: {
      name: 'Табак',
    },
    perfumery: {
      name: 'Парфюм',
    },
    tires: {
      name: 'Шины',
    },
    electronics: {
      name: 'Фототехника',
    },
    pharma: {
      name: 'Лекарства',
    },
    milk: {
      name: 'Молоко',
    },
    bicycle: {
      name: 'Велосипеды',
    },
    wheelchairs: {
      name: 'Кресла-коляски',
    },
  },
  overEntityIds: 'Документ',
};
