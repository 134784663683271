import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import download from 'downloadjs';
import { translate, SimpleForm, showNotification, TextInput, FileInput, FileField, SelectInput, Toolbar, SaveButton, required } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import request from '../../../../network/request';
import { paramToDictionary, asyncTimeout } from '../../../../utils';
import { restProviderHandle } from '../../../../network/rest';
import printPDF from '../../../../utils/printPDF';
import Snackbar from '../../../../components/Snackbar';
import { Divider } from '@material-ui/core';

const styles = {
  head: {
    display: 'flex',
  },
  label: { width: '10em', display: 'inline-block' },
  toolbar: { background: 'none' },
  buttonsContainer: {
    margin: '20px 0 30px',
    display: 'flex',
  },
  button: { marginRight: 10 },
  loader: {
    marginLeft: 20
  },
  card: {
    margin: '20px 0 0'
  }
};

const FormToolbar = translate(({ translate, disabled, disabledSend, reset, ...props }) => (
  <Toolbar
    style={styles.toolbar}
    {...props}
  >
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
      style={styles.button}
      disabled={disabled || disabledSend || props.saving}
    />
    <Button
      type={'button'}
      onClick={reset}
      disabled={disabled || props.saving}
    >
      {translate(`resources.${resourceConfig.name}.resetAction`)}
    </Button>
    {props.saving &&
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    }
  </Toolbar>
))

class AppModule extends Component {
  defaultParams = {
  }

  lastFormData = {}
  retryCount = 0;
  maxRetryCount = 15;
  retryInterval = 3000;
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      executing: false,
      formId: 1,
      templateNames: [],
      journals: null,
      params: {
        ...this.defaultParams
      }
    };
  }

  componentDidMount = () => {
    this.checkLocation();
    this.loadTemplates();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);


    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.lastFormData = {};
    this.retryCount = 0;
    this.setState({
      show: false,
      executing: false,
    })
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      journals: null,
      params: {
        ...this.defaultParams
      }
    })
  }
  
  reset = () => {
    this.setState(state => ({
      executing: false,
      formId: state.formId + 1,
      journals: null,
      params: {
        ...state.params,
        ...this.lastFormData,
        file: null,
      }
    }), () => {
      this.lastFormData = {};
      this.retryCount = 0;
    })
  }

  close = () => {
    if (this.state.processing) {
      return;
    }
    this.props.history.goBack();
  }

  loadTemplates = async () => {
    const templates = await restProviderHandle('GET_LIST', 'marking--labelTemplate', { pagination: { perPage: 200, page: 1 } });

    if (templates.data) {
      this.setState({
        templateNames: templates.data,
      });
    }
  }

  loadPrintJournals = async (ids) => {
    const printJournal = await restProviderHandle('GET_MANY', 'marking--printJournal', { ids });

    return printJournal.data
  }

  printPrintJournal = async (printJournal, type = '') => {
    let data = {
      path: printJournal.pdfPath,
    };
    const response = await request({
      apiService: 'shoes',
      path: '/admin/print/func/load',
      urlParams: data,
      method: 'GET',
      responseType: 'blob',
    })

    if (!response.ok) {
      throw new Error()
    }

    const blob = response.data;
    if (type === 'print') {
      printPDF(blob);
    } else if (type === 'download') {
      let fileName = `${printJournal.filename || printJournal.id}.pdf`
      let mimeType = 'application/pdf'
      download(blob, fileName, mimeType);
    }

    return {}
  }

  updatePrinJournals = async (ids = null) => {
    const {
      journals,
      show,
      formId,
    } = this.state;

    if (!show || !journals || journals.length === 0) {
      return;
    }

    if (ids === null) {
      ids = journals.filter(x => !x.pdfPath).map(x => x.id);
    }
    const newJournals = await this.loadPrintJournals(ids);
    
    if (newJournals) {
      const pdfs = newJournals.filter(x => x.pdfPath);
      this.setState(state => ({
        journals: state.journals.map(x => pdfs.find(p => p.id === x.id) || x),
      }))
    }

    const nextIds = newJournals.filter(x => !x.pdfPath).map(x => x.id);
    if (nextIds.length > 0) {
      await asyncTimeout(this.retryInterval);
      if (!this.state.show || this.state.formId !== formId) {
        return;
      }
      return await this.updatePrinJournals(nextIds);
    }
  }

  execute = async (formData) => {
    this.setState({
      executing: true
    });
    let data = {
      ...formData
    }

    let response = null;
    try {
      var fetchBody = new FormData();

      if (data.file && data.file.rawFile) {
        fetchBody.append('file', data.file.rawFile);
      }
      delete data.file
      for (var name in data) {
        fetchBody.append(name, data[name]);
      }

      response = await request({
        apiService: 'shoes',
        path: '/admin/print/func/print_by_xls',
        body: fetchBody,
        method: 'POST',
      })
    } catch (e) {
      console.error(e);
    }
    let error = null;
    let journals = null;
    if (response === null || !response.ok || !response.data) {
      this.props.showNotification(`resources.${resourceConfig.name}.errorMessage`, 'error');
      if (response !== null) {
        error = response.data || 'Unknown error';
      }
    } else if (response.data && response.data.length === 0) {
      this.props.showNotification(`resources.${resourceConfig.name}.nothingToCreate`, 'error');
      error = 'No created print journals'
    } else {
      journals = await this.loadPrintJournals(response.data);
    }

    this.setState({
      error,
      executing: false,
      journals,
    }, this.updatePrinJournals);
  }

  render() {
    const {
      translate
    } = this.props;
    const {
      templateNames,
      journals,
    } = this.state;
    const validateRequired = required();
    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.close}
      >
        <div key={this.state.formId}>
          <SimpleForm
            defaultValue={this.state.params}
            saving={this.state.executing}
            save={this.execute}
            toolbar={<FormToolbar reset={this.reset} disabledSend={journals} />}
            validate={this.validate}
            style={{ width: 600 }}
          >
            <div style={styles.head}>
              <Typography
                fullWidth
                variant={'title'}
              >
                {translate(`resources.${resourceConfig.name}.title`)}
              </Typography>
              {(this.state.searchingGoods || this.state.searchingPrintJournalElement) && (
                <div style={styles.loader}>
                  <CircularProgress size={20} />
                </div>
              )}
            </div>

            {templateNames.length === 0 ? (
              <TextInput
                source={'label'}
                disabled={this.state.searchingPrintJournalElement}
                fullWidth
                label={translate(`resources.${resourceConfig.name}.fields.label`)}
                validate={validateRequired}
              />
            ) : (
                <SelectInput
                  fullWidth
                  source={'label'}
                  disabled={this.state.searchingPrintJournalElement}
                  choices={templateNames}
                  optionText="name"
                  optionValue="name"
                  label={translate(`resources.${resourceConfig.name}.fields.label`)}
                  validate={validateRequired}
                />
              )}

            <FileInput source="file" validate={validateRequired}>
              <FileField source="src" title="title" />
            </FileInput>
            {journals && (
              <List dense fullWidth>
                {journals.map((journal, index) => (
                  <Fragment key={journal.id}>
                    {index > 0 && (<Divider />)}
                    <ListItem>
                      <ListItemText
                        primary={journal.filename}
                        secondary={`ID = ${journal.id}`}
                      />
                      {!journal.pdfPath && (
                        <ListItemSecondaryAction>
                          <IconButton disabled>
                            <CircularProgress size={24} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                      {journal.pdfPath && (
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => this.printPrintJournal(journal, 'download')}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton onClick={() => this.printPrintJournal(journal, 'print')}>
                            <PrintIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            )}

            {this.state.error && (
              <Snackbar
                style={{ marginTop: 20, marginBottom: 40, maxWidth: '100%' }}
                textStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                showIcon={false}
                variant="error"
                message={this.state.error.message}
                fullWidth
              />
            )}


          </SimpleForm>
        </div>
      </Drawer>
    )
  }
}

export default compose(
  withRouter,
  connect(null, { showNotification }),
  translate,
  withStyles(styles)
)(AppModule);
