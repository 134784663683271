import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        KM_SPOILED: 'испорчен',
        KM_LOST: 'утерян',
        KM_DESTROYED: 'уничтожен',
      },
    }
  }
}
