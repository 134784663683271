import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'CANCELLATION_APPLIED': 'Списан после нанесения на товар',
        'REMARK_RETIRED': 'Перемаркирован',
        'RETIRED_CANCELLATION': 'Списан',
        'DONATION': 'Безвозмездная передача товара',
        'STATE_ENTERPRISE': 'Приобретение гос.предприятием',
        'NO_RETAIL_USE': 'Использование для собственных нужд покупателем',
        'BEYOND_EEC_EXPORT': 'Экспорт за пределы стран ЕАЭС',
        'REMOTE_SALE': 'Продажа по образцам, дистанционный способ продажи',
        'EEC_EXPORT': 'Экспорт в страны ЕАЭС',
        'RETURN': 'Возврат физическому лицу',
        'DAMAGE_LOSS': 'Утрата или повреждение',
        'DESTRUCTION': 'Уничтожение',
        'CONFISCATION': 'Конфискация',
        'LIQUIDATION': 'Ликвидация предприятия',
      },
    }
  }
}