import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { translate, useLocale, useSetLocale, Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { setLangToStorage } from '../i18n';
import { setTheme } from '../redux/app/actions';

const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
};

const Configuration = (props) => {
    const {
        classes,
        theme,
        setTheme,
        translate,
    } = props;

    const locale = useLocale();
    const raSetLocale = useSetLocale();
    
    const setLocale = (locale) => {
        raSetLocale(locale)
        setLangToStorage(locale)
    }

    return (
        <Card>
            <Title title={translate('pos.configuration')} />
            <CardContent>
                <div className={classes.label}>{translate('pos.theme.name')}</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={theme === 'light' ? 'primary' : 'default'}
                    onClick={() => setTheme('light')}
                >
                    {translate('pos.theme.light')}
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={theme === 'dark' ? 'primary' : 'default'}
                    onClick={() => setTheme('dark')}
                >
                    {translate('pos.theme.dark')}
                </Button>
            </CardContent>
            <CardContent>
                <div className={classes.label}>{translate('pos.language')}</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'en' ? 'primary' : 'default'}
                    onClick={() => setLocale('en')}
                >
                    English
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'ru' ? 'primary' : 'default'}
                    onClick={() => setLocale('ru')}
                >
                    Русский
                </Button>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = state => ({
    theme: state.app.theme,
});

export default compose(
    connect(
        mapStateToProps,
        {
            setTheme
        }
    ),
    translate,
    withStyles(styles)
)(Configuration);
