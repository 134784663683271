import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Product group |||| Product groups',
      arrayInputLabel: 'Product groups',
      setAsDefault: {
        action: 'Set as default',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        'company.id': 'Company',
        uniqName: 'Product group',
      },
    }
  }
}