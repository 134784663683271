export default {
  services: {
    marking: {
      name: 'Маркировка'
    }
  },
  documentsSendError: {
    sending: 'Документ № %{id} отправлен',
    success: 'Документ № %{id} проведен',
  }
}